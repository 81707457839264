import React from "react"

const Player = ({ username, ...props }: { username: string } & React.HTMLAttributes<HTMLDivElement>) => {

  return (
    <div className="Player" {...props}>
      <img src="public/user.svg" alt="UserAvatar. Icon made by www.flaticon.com" />
      <span>{username}</span>
    </div>
  )
}

export default React.memo(Player)