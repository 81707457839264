import { createSlice, PayloadAction } from "@reduxjs/toolkit"

// TODO make this explicit
export interface Progress {
  EASY: number
  MEDIUM: number
  HARD: number
  // [difficulty: string]: number
}

const initialState: Progress = {
  EASY: 0,
  MEDIUM: 0,
  HARD: 0
}

const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    setProgress: (state, action: PayloadAction<Progress>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setProgress } = progressSlice.actions

export default progressSlice.reducer