import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: string = ''

const opponentSlice = createSlice({
  name: 'opponent',
  initialState,
  reducers: {
    setOpponent: (state, action: PayloadAction<string>) => {
      return state = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setOpponent } = opponentSlice.actions

export default opponentSlice.reducer