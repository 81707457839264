import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface UserInfo {
  username: string
  level: number
  // rank: string
  totalScore: number
  currentLevelBaseScore: number
  scoreToNextLevel: number
  // badge: any
  // addFriend: boolean
}

const initialState: UserInfo = {
  username: "",
  level: 0,
  totalScore: 0,
  currentLevelBaseScore: 0,
  scoreToNextLevel: 0

}

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUserInfo } = userInfoSlice.actions

export default userInfoSlice.reducer
