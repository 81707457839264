import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface Data {
  home: string
  protected: string
}

const initialState: Data = {
  home: '',
  protected: ''
}

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<Data>>) => {
      state.home = action.payload.home || state.home
      state.protected = action.payload.protected || state.protected
    }
  },
})

// Action creators are generated for each case reducer function
export const { setData } = dataSlice.actions

export default dataSlice.reducer