import PlayContainer from 'components/Game/PlayContainer';
import GameSelection from 'components/GameSelection/GameSelection';
import HomeContainer from 'components/Home/HomeContainer';
import Instructions from 'components/Instructions/Instructions';
import Leaderboard from 'components/Leaderboard/Leaderboard';
import InviteScreen from 'components/Lobby/InviteScreen';
import LobbyContainer from 'components/Lobby/LobbyContainer';
import LoginContainer from 'components/Login/LoginContainer';
import LogoutContainer from 'components/Logout/LogoutContainer';
import ProlificEntry from 'components/Prolific/ProlificEntry';
import ExitQuestionnaire from 'components/Questionnaire/ExitQuestionnaire';
import Questionnaire from 'components/Questionnaire/Questionnaire';
import RegisterContainer from 'components/Register/RegisterContainer';
import Store from 'components/Store/Store';
import UserProfile from 'components/UserProfile/UserProfile';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import WebSocketProvider from 'utils/WebSocketProvider';
import { useAuth } from 'utils/auth';

const PrivateRoute = () => {
  const [logged] = useAuth();
  let location = useLocation();

  return logged ? (
    <WebSocketProvider>
      <Outlet />
    </WebSocketProvider>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export const MyRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginContainer />} />
        <Route path="/register" element={<RegisterContainer />} />
        <Route path="/logout" element={<LogoutContainer />} />
        <Route path="/prolific" element={<ProlificEntry />} />

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<HomeContainer />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/game_selection" element={<GameSelection />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/store" element={<Store />} />
          <Route path="/howto" element={<Instructions />} />
          <Route path="/questionnaire" element={<Questionnaire />} />
          <Route path="/play" element={<PlayContainer />} />
          <Route path="/join_room/room-:roomId" element={<InviteScreen />} />
          {/*<Navigate replace to="/room-:roomId" />*/}
          <Route path="/room-:roomId" element={<LobbyContainer />} />
          <Route path="/exit_questionnaire" element={<ExitQuestionnaire />} />
        </Route>

        {/* <Route path='*' element={<Navigate to="/" />}/> */}
      </Routes>
      <Toaster
        toastOptions={{
          duration: 5000,
        }}
      />
    </BrowserRouter>
  );
};
