import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ItCard } from "store/slices/itCardSlice";

const initialState: ItCard = {
  key: "",
  imageUrl: "",
};

const opponentItCardSlice = createSlice({
  name: "opponentItCard",
  initialState,
  reducers: {
    setOpponentItCard: (state, action: PayloadAction<ItCard>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOpponentItCard } = opponentItCardSlice.actions;

export default opponentItCardSlice.reducer;
