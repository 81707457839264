import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HintType } from "store/slices/shopSlice";

export interface InventoryInfo {
  [key: string]: number;
}

const initialState: InventoryInfo = { };

const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setInventory: (state, action: PayloadAction<InventoryInfo>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInventory } = inventorySlice.actions;

export default inventorySlice.reducer;
