import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface Relation {
  key: number
  name: string
  question: string
}

const initialState: Relation[] = []

const relationSlice = createSlice({
  name: 'relation',
  initialState,
  reducers: {
    setRelations: (state, action: PayloadAction<Relation[]>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setRelations } = relationSlice.actions

export default relationSlice.reducer