import { Link } from 'react-router-dom'

export default function Questionnaire() {



  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", alignItems: "center" }}>
      <Link to="/" className="mb-4 AuthButton btn btn-primary" style={{ padding: '1em', maxWidth: "20em", marginTop: "2em" }}>Back to main menu</Link>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdPI_UcJRGqVapMnOLXBnkVHSc_CEkdesZdEDSjzNRsbq-fWw/viewform?embedded=true" style={{ width: "100%", height: "100%", margin: "0" }} className="mb-8">Loading…</iframe>
    </div>
  )
}
