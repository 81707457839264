import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const enum ViewMode {
  TEXT = 'text',
  IMAGE = 'image',
  BOTH = 'both',
}

const initialState: string = ViewMode.IMAGE;

const viewModeSlice = createSlice({
  name: 'viewMode',
  initialState,
  reducers: {
    setViewMode: (state, action: PayloadAction<string>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setViewMode } = viewModeSlice.actions;

export default viewModeSlice.reducer;
