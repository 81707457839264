import React from "react";
import Avatar from "avataaars";

export interface AvatarProps {
  className: string;
  topType: string;
  accessoriesType: string;
  hairColor: string;
  facialHairType: string;
  clotheType: string;
  eyeType: string;
  eyebrowType: string;
  mouthType: string;
  skinColor: string;
}

export default function Avataar({
  className,
  topType,
  accessoriesType,
  hairColor,
  facialHairType,
  clotheType,
  eyeType,
  eyebrowType,
  mouthType,
  skinColor,
}: AvatarProps) {
  return (
    <Avatar
      className={className}
      avatarStyle="Transparent"
      topType={topType}
      accessoriesType={accessoriesType}
      hairColor={hairColor}
      facialHairType={facialHairType}
      clotheType={clotheType}
      eyeType={eyeType}
      eyebrowType={eyebrowType}
      mouthType={mouthType}
      skinColor={skinColor}
    />
  );
}
//
// export function defaultAvatar1() {
//   return avatar({
//     topType: 'LongHairStraight',
//     accessoriesType: 'Kurt',
//     hairColor: 'PastelPink',
//     facialHairType: 'Blank',
//     clotheType: 'BlazerShirt',
//     eyeType: 'Default',
//     eyebrowType: 'Default',
//     mouthType: 'Default',
//     skinColor: 'Light'
//   });
// }
//
// export const defaultAvatar2 = () =>
//   avatar({topType: '',
//     accessoriesType: '', hairColor: '', facialHairType: 'Blank', clotheType: '', eyeType: '',
//     eyebrowType: '', mouthType: '', skinColor: ''});
