import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: string = ''

const answerSlice = createSlice({
  name: 'answer',
  initialState,
  reducers: {
    setAnswer: (state, action: PayloadAction<string>) => {
      return state = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAnswer } = answerSlice.actions

export default answerSlice.reducer