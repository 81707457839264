import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface OnlineCount {
  waiting: number[],
  playing: number
}

const initialState: OnlineCount = {
  waiting: [],
  playing: 0
}

const onlineCountSlice = createSlice({
  name: 'onlineCount',
  initialState,
  reducers: {
    setOnlineCount: (state, action: PayloadAction<OnlineCount>) => {
      state.playing = action.payload.playing
      state.waiting = action.payload.waiting
    }
  },
})

// Action creators are generated for each case reducer function
export const { setOnlineCount } = onlineCountSlice.actions

export default onlineCountSlice.reducer