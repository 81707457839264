import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: string = ''

const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setQuestion: (state, action: PayloadAction<string>) => {
      return state = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setQuestion } = questionSlice.actions

export default questionSlice.reducer