import HomeView from "components/Home/HomeView";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setFreshLogin } from "store/slices/freshLoginSlice";
import { useAuth } from "utils/auth";
import { useWebSocket } from "utils/WebSocketProvider";

export default function HomeContainer() {
  const [logged] = useAuth();
  const dispatch = useAppDispatch();
  const isFreshLogin = useAppSelector((state) => state.freshLogin);

  useEffect(() => {
    if (isFreshLogin) {
      console.log("New login, refreshing and clearing caches...");
      dispatch(setFreshLogin(false));
      window.location.reload();
    }
  }, []);

  return <div>{!logged ? <Navigate to="/login" /> : <HomeView />}</div>;
}
