import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: number = 0;

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setCurrency: (state, action: PayloadAction<number>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrency } = currencySlice.actions;

export default currencySlice.reducer;
