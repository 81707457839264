import { combineReducers, configureStore } from '@reduxjs/toolkit';
import answerSlice from 'store/slices/answerSlice';
import askerActionSlice from 'store/slices/askerActionSlice';
import authFormSlice from 'store/slices/authFormSlice';
import cardsSlice from 'store/slices/cardsSlice';
import completedSlice from 'store/slices/completedSlice';
import currencySlice from 'store/slices/currencySlice';
import dataSlice from 'store/slices/dataSlice';
import difficultySlice from 'store/slices/difficultySlice';
import errorMessageSlice from 'store/slices/errorMessageSlice';
import forfeitedSlice from 'store/slices/forfeitedSlice';
import freshLoginSlice from 'store/slices/freshLoginSlice';
import gainedExpSlice from 'store/slices/gainedExpSlice';
import gameoverSlice from 'store/slices/gameoverSlice';
import guessCardSlice from 'store/slices/guessCardSlice';
import instructionShownSlice from 'store/slices/instructionShownSlice';
import inventorySlice from 'store/slices/inventorySlice';
import itCardSlice from 'store/slices/itCardSlice';
import loadingSlice from 'store/slices/loadingSlice';
import meSlice from 'store/slices/meSlice';
import myTurnSlice from 'store/slices/myTurnSlice';
import onlineCountSlice from 'store/slices/onlineCountSlice';
import opponentItCardSlice from 'store/slices/opponentItCardSlice';
import opponentSlice from 'store/slices/opponentSlice';
import progressSlice from 'store/slices/progressSlice';
import questionHistorySlice from 'store/slices/questionHistorySlice';
import questionSlice from 'store/slices/questionSlice';
import relationSlice from 'store/slices/relationSlice';
import reportCheckedSlice from 'store/slices/reportCheckedSlice';
import roleSlice from 'store/slices/roleSlice';
import sendingRequestSlice from 'store/slices/sendingRequestSlice';
import sessionSlice from 'store/slices/sessionSlice';
import shopSlice from 'store/slices/shopSlice';
import stageSlice from 'store/slices/stageSlice';
import targetInputSlice from 'store/slices/targetInputSlice';
import targetRelationSlice from 'store/slices/targetRelationSlice';
import viewModeSlice from 'store/slices/viewModeSlice';
import leaderboardSlice from './slices/leaderboardSlice';
import roomInfoSlice from './slices/roomInfoSlice';
import userInfoSlice from './slices/userInfoSlice';

export const store = configureStore({
  reducer: {
    authForm: authFormSlice,
    currentlySending: sendingRequestSlice,
    isLoading: loadingSlice,
    errorMessage: errorMessageSlice,
    data: dataSlice,
    freshLogin: freshLoginSlice,
    onlineCount: onlineCountSlice,
    progress: progressSlice,
    completed: completedSlice,
    gameSession: sessionSlice,
    gameDifficulty: difficultySlice,
    instructionsShown: instructionShownSlice,
    leaderboard: combineReducers({
      leaderboard: leaderboardSlice,
    }),
    currency: currencySlice,
    inventory: inventorySlice,
    shop: shopSlice,
    room: roomInfoSlice,
    me: meSlice,
    game: combineReducers({
      myTurn: myTurnSlice,
      role: roleSlice,
      cards: cardsSlice,
      itCard: itCardSlice,
      opponentItCard: opponentItCardSlice,
      me: meSlice,
      opponent: opponentSlice,
      relations: relationSlice,
      target: combineReducers({
        index: targetRelationSlice,
        value: targetInputSlice,
      }),
      action: askerActionSlice,
      guess: guessCardSlice,
      stage: stageSlice,
      question: questionSlice,
      answer: answerSlice,
      questionHistory: questionHistorySlice,
      reportChecked: reportCheckedSlice,
      viewMode: viewModeSlice,
      forfeited: forfeitedSlice,
      gameover: gameoverSlice,
      gainedExp: gainedExpSlice,
    }),
    userInfo: userInfoSlice,
  },
  devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
