import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: string = ''

const errorSlice = createSlice({
  name: 'errorMessage',
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string>) => {
      return state = action.payload
    },
    clearErrorMessage: (state) => {
      return state = ''
    }
  },
})

// Action creators are generated for each case reducer function
export const { setErrorMessage, clearErrorMessage } = errorSlice.actions

export default errorSlice.reducer