import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { withTimeout } from 'utils/utils';
import { useWebSocket } from '../../utils/WebSocketProvider';
import GameView from './GameView';
import GameoverView from './GameoverView';

const PlayContainer = () => {
  const socket = useWebSocket();
  const gameover = useAppSelector((state) => state.game.gameover);
  const navigate = useNavigate();

  useEffect(() => {
    // check if we have all the items, otherwise go back to lobby
    socket?.emit('inActiveGame', (inActiveGame: boolean) => {
      if (!inActiveGame) {
        navigate('/game_selection');
      } else {
        socket?.emit(
          'getWholeGame',
          withTimeout(
            () => {},
            () => {
              toast.error(
                `Request timed out! There might be an server-side error. Please contact the developer and refresh.`,
              );
            },
            5000,
          ),
        );
      }
    });
  }, [socket]);

  return <div>{gameover ? <GameoverView /> : <GameView />}</div>;
};

export default React.memo(PlayContainer);
