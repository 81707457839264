import React from "react"
import { Button } from "@mui/material"
import { ACTION_NONE } from "constants/AppConstants"
import { useDispatch } from "react-redux"
import { setGuessCard } from "store/slices/guessCardSlice"
import { setTargetRelation } from "store/slices/targetRelationSlice"
import { Action, setAskerAction } from "store/slices/askerActionSlice"

const BackButton = () => {
  const dispatch = useDispatch()

  const handleBack = () => {
    dispatch(setGuessCard(-1))
    dispatch(setTargetRelation(-1))
    dispatch(setAskerAction(Action.NONE))
  }

  return (<Button
    color="secondary"
    variant="contained"
    size="large"
    className="BigButton BackButton"
    onClick={handleBack}
    disableElevation
  >BACK</Button>)
}

export default React.memo(BackButton)