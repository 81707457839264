import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: string = ''

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<string>) => {
      return state = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setRole } = roleSlice.actions

export default roleSlice.reducer