import { Dispatch } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { createAuthProvider } from 'react-token-auth';
import { setCompleted } from 'store/slices/completedSlice';
import { setErrorMessage } from 'store/slices/errorMessageSlice';
import { setMe } from 'store/slices/meSlice';
import { setOnlineCount } from 'store/slices/onlineCountSlice';
import { setProgress } from 'store/slices/progressSlice';
import { sendingRequest } from 'store/slices/sendingRequestSlice';
import api from 'utils/api';
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../constants/AppConstants';

export interface Session {
  accessToken: string;
  refreshToken?: string;
}

// Probably needs to be fixed
export interface AuthenticationPayload {
  success: boolean;
  data: {
    accessToken: string;
    refreshToken: string;
  };
}

export interface RequestError {
  success: boolean;
  error: {
    code: number;
    message: string;
    details: string;
  };
}

export const [useAuth, authFetch, authLogin, authLogout] = createAuthProvider<Session>({
  accessTokenKey: 'accessToken',
  storage: localStorage,
  onUpdateToken: (token) =>
    api
      .post<AuthenticationPayload>('api/v1/auth/refresh', {
        method: 'POST',
        body: {
          refreshToken: token.refreshToken,
        },
      })
      .then((r) => {
        console.log({ r });
        return getTokensAsSession(r.data);
      })
      .finally(() => {
        localStorage.removeItem('REACT_TOKEN_AUTH_KEY');
      }),
});

// export const [useAuth, authFetch, authLogin, authLogout] = createAuthProvider({
//   accessTokenKey: 'accessToken',
//   onUpdateToken: (token) => {
//     refreshToken = token.refreshToken
//     api.post('/api/v1/auth/refresh', {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${refreshToken}`
//       }
//     })
//       .then(r => r.json())
//       .finally(() => {
//         localStorage.removeItem('REACT_TOKEN_AUTH_KEY')
//       })
//   }
// })

export const getAccessToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);
  if (!accessToken) {
    removeAccessToken();
    return null;
  }
  return accessToken;
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME);
  if (!refreshToken) {
    removeRefreshToken();
    return null;
  }
  return refreshToken;
};

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
};

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_NAME);
};

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_NAME);
};

export const clearTokens = () => {
  removeAccessToken();
  removeRefreshToken();
  localStorage.removeItem('REACT_TOKEN_AUTH_KEY');
};

const getTokensAsSession = (payload: AuthenticationPayload): Session => {
  return {
    accessToken: payload.data.accessToken,
    refreshToken: payload.data.refreshToken,
  };
};

// --------------- AXIOS

export const login = (username: string, password: string) => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true));
    dispatch(setErrorMessage(''));
    api
      .post(`/api/v1/auth/login`, { username, password })
      .then((response) => {
        console.log(JSON.stringify(response));
        authLogin(response.data.data);
        setAccessToken(response.data.data.accessToken);
        setRefreshToken(response.data.data.refreshToken);
        // dispatch(setAuthState(data.data.isLoggedIn))
      })
      .catch((error) => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText));
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false));
      });
  };
};

export const register = (username: string, password: string, pid: string | undefined = undefined) => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true));
    dispatch(setErrorMessage(''));
    api
      .post<AuthenticationPayload>(`/api/v1/auth/register`, pid ? { username, password, pid } : { username, password })
      .then((response) => {
        if (response.data.success) {
          login(username, password)(dispatch);
        } else {
          console.log(response.data);
          dispatch(setErrorMessage('Something went wrong, please try again.'));
        }
      })
      .catch((error: AxiosError<RequestError>) => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.data.error.message));
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false));
      });
  };
};

export const refreshToken = () => {
  api
    .post<AuthenticationPayload>('/api/v1/auth/refresh', {
      refreshToken: getRefreshToken(),
    })
    .then((response) => {
      setAccessToken(response.data.data.accessToken);
      setRefreshToken(response.data.data.refreshToken);
      return Promise.resolve();
    })
    .catch((error) => {
      console.log(error);
      clearTokens();
      return Promise.reject();
    });
};

export const loadMe = () => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true));
    dispatch(setErrorMessage(''));
    api
      .get(`/api/v1/auth/me`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((response) => {
        // No problem, token still valid
        dispatch(setMe(response.data.data.username));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText));
        }
        toast.error('Login session expired. Please log in again.', {
          id: 'credentials',
          duration: 5000,
        });
        authLogout();
        clearTokens();
      })
      .finally(() => {
        dispatch(sendingRequest(false));
      });
  };
};

export const loadOnlinePlayers = () => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true));
    dispatch(setErrorMessage(''));
    api
      .get(`/api/v1/auth/players_online`)
      .then((data) => {
        dispatch(setOnlineCount(data.data));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText));
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false));
      });
  };
};

export const refreshProgress = () => {
  return (dispatch: Dispatch) => {
    api
      .get('/api/v1/auth/progress', {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
        timeout: 5000,
      })
      .then((data) => {
        const p = data.data.progress;
        const completed = data.data.completed ? true : false;
        if (p !== undefined && p !== null) {
          dispatch(setProgress(p));
          dispatch(setCompleted(completed));
        } else {
          // TODO else condition for when the server could not find user / not is a user without prolific id.
        }
      });
  };
};

export const logout = () => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true));
    dispatch(setErrorMessage(''));
    api
      .get('/api/v1/auth/logout', {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((data) => {
        authLogout();
        clearTokens();
        localStorage.clear();
      })
      .catch((error) => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText));
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false));
      });
  };
};
