import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWebSocket } from "utils/WebSocketProvider";

const InviteScreen = () => {
  const socket = useWebSocket();
  const navigate = useNavigate();
  const { roomId } = useParams();

  useEffect(() => {
    socket?.emit("join", roomId, (response: any) => {
      navigate(`/room-${roomId}`);
    });
  }, [socket]);

  return <></>;
};

export default InviteScreen;
