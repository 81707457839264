import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: boolean = false

const gameoverSlice = createSlice({
  name: 'gameover',
  initialState,
  reducers: {
    setGameover: (state, action: PayloadAction<boolean>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setGameover } = gameoverSlice.actions

export default gameoverSlice.reducer