import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import ErrorView from 'components/ErrorView'
import MyInput from 'components/Input'
import LoadingView from 'components/LoadingView'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Alert } from 'react-st-modal'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { register } from 'utils/auth'

interface LocationState {
  pid: string
}

const RegisterForm = () => {
  const currentlySending = useAppSelector(state => state.currentlySending)
  const errorMessage = useAppSelector(state => state.errorMessage)
  const authForm = useAppSelector(state => state.authForm)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const locationState = location.state as LocationState
  const pid = locationState?.pid // Type Casting, then you can get the params passed via router
  const with_pid = !(location.state === undefined || pid === undefined || pid === null || pid === "")

  useEffect(() => {
    // If we come from the prolific entry
    if (with_pid) {
      Alert(<div><h1>Welcome dear Prolific user!</h1> <p><b>Thank you for joining our research about Games With A Purpose!</b><br /><br />You can register with a username of your liking and use it to access the game even after your tasks are complete. Please make sure you remember or save your password, since it is currently <b>not possible to reset the password</b>. <br />
        <b>Play honestly: if we detect any cheating behaviour, your submission will be rejected and you will not receive any rewards. For every game that you win, you will also receive a bonus of 0.15£!</b>
      </p> </div>, 'Prolific disclaimer').then(() => { })
    }
  }, [])

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault()
    if (with_pid) {
      dispatch(register(authForm.username, authForm.password, pid))
    } else {
      dispatch(register(authForm.username, authForm.password))
    }
  }

  const [showPass, setShowPass] = useState(false)

  const handleClickShowPassword = () => {
    setShowPass(!showPass)
  }

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault()
  }

  return (
    <form onSubmit={submitForm} className="flex flex-col gap-6">
      {/* {with_pid ? <div className="mb-4">Registering with prolific id {location.state.pid}</div> : ''} */}
      <MyInput id="username" type="text" name="username" model="username" placeholder="Username" className="mb-6 text-center" autofocus />
      <MyInput id="new-password" type={showPass ? 'text' : 'password'} name="new-password" model="password"
        placeholder="Password" className="mb-6" endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}>
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        } />
      <input type="submit" value="Register" className="mb-4 AuthButton btn btn-primary" />
      <LoadingView currentlySending={currentlySending} />
      <ErrorView message={errorMessage} />
    </form>
  )
}

export default RegisterForm
