import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum Action {
  NONE = "none",
  ASK = "ask",
  GUESS = "guess",
}

const initialState = Action.NONE;

const askerActionSlice = createSlice({
  name: "askerAction",
  initialState,
  reducers: {
    setAskerAction: (state, action: PayloadAction<Action>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAskerAction } = askerActionSlice.actions;

export default askerActionSlice.reducer;
