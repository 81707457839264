import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface AuthForm {
  username: string
  password: string
}

const initialState: AuthForm = {
  username: '',
  password: ''
}

const authFormSlice = createSlice({
  name: 'authForm',
  initialState,
  reducers: {
    changeAuth: (state, action: PayloadAction<Partial<AuthForm>>) => {
      state.username = (action.payload.username !== undefined) ? action.payload.username : state.username
      state.password = (action.payload.password !== undefined) ? action.payload.password : state.password
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeAuth } = authFormSlice.actions

export default authFormSlice.reducer