import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: boolean = false

const freshLoginSlice = createSlice({
  name: 'freshLogin',
  initialState,
  reducers: {
    setFreshLogin: (state, action: PayloadAction<boolean>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setFreshLogin } = freshLoginSlice.actions

export default freshLoginSlice.reducer