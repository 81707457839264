import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Player from "components/Game/Player/Player";

export interface Player {
  position: number;
  rankTier: string;
  player: string;
  level: number;
  score: number;
  // badge: List[Badge],
  // addFriend: boolean
}

export interface LeaderboardInfo {
  me: Player;
  players: Player[];
}

const initialState: LeaderboardInfo = {
  me: { position: 0, rankTier: "", player: "", level: 0, score: 0 },
  players: [],
};

const leaderboardSlice = createSlice({
  name: "leaderboard",
  initialState,
  reducers: {
    setLeaderboard: (state, action: PayloadAction<LeaderboardInfo>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLeaderboard } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;
