import React, { useEffect, useState } from "react";
import { useDialog } from "react-st-modal";

const FreeTextDialog = ({ hints }: { hints: number }) => {
  const dialog = useDialog();

  const handleSubmit = (result: boolean) => {
    dialog.close(result);
  };

  return (
    <div id="FreeText">
      <div style={{ gridArea: "text", alignSelf: "start" }}>
        <span className="mb-4 text-large">
          Free text questions cost hints. Do you want to use a hint? <br />
        </span>
        <span className="mb-4 text-large">
          <b>Available hints: </b>x{hints}
        </span>
      </div>
      <button
        className="AuthButton btn btn-primary no-shadow"
        style={{ gridArea: "back", width: "100%", height: "100%" }}
        onClick={() => handleSubmit(false)}
      >
        Cancel
      </button>
      <button
        className="AuthButton btn btn-alert no-shadow"
        style={{ gridArea: "proceed", width: "100%", height: "100%" }}
        onClick={() => handleSubmit(true)}
      >
        Proceed anyway
      </button>
    </div>
  );
};

export default React.memo(FreeTextDialog);
