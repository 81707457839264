import { Button } from '@mui/material'
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'
import { COMPLETION_LINK } from 'constants/AppConstants'
import { CircularProgressWithLabel, clamp, getRemainingTasks, openInNewTab } from 'utils/utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'

const ProgressMenu = () => {
  const dispatch = useAppDispatch()
  const progress = useAppSelector(state => state.progress)
  const completed = useAppSelector(state => state.completed)
  var [remainingTasks, _] = getRemainingTasks(progress)

  const complete = () => {
    openInNewTab(COMPLETION_LINK)
  }

  return (
    <div id="ProgressMenu">
      <CircularProgressWithLabel value={(5 - remainingTasks) * 20} />
      <div className="mb-4"></div>

      <div className="mb-8 ProgressMenu-Table">
        <span className="ProgressMenu-Table_progress">{progress['EASY'] >= 3 ? <CheckCircle color="secondary" /> : <CheckCircleOutline className="text-disabled ProgressMenu-Table_icon" />}
          {clamp(progress['EASY'], 0, 3)}/3</span> <span className="ProgressMenu-Table_description">Easy difficulty</span>
        <span className="ProgressMenu-Table_progress">{progress['MEDIUM'] >= 2 ? <CheckCircle color="secondary" /> : <CheckCircleOutline className="text-disabled ProgressMenu-Table_icon" />}
          {clamp(progress['MEDIUM'], 0, 2)}/2</span> <span>Medium difficulty</span>
        {/* <span className="ProgressMenu-Table_progress">{progress['HARD'] >= 2 ? <CheckCircle color="secondary" /> : <CheckCircleOutline className="text-disabled ProgressMenu-Table_icon" />}
          {clamp(progress['HARD'], 0, 1)}/2 </span> <span>Hard difficulty</span> */}
      </div>
      {completed ? <Button onClick={complete} className="mb-8 MenuButton text-large">Complete study</Button> :
        remainingTasks === 0 ? <Link to="/exit_questionnaire" className="mb-8 MenuButton text-large">Proceed to ending questionnaire</Link> : null}
      <span className="text-small">It might take a minute to update the progress. Tap outside and reopen this window to refresh.<br />Once you complete the tasks you will be given a link to proceed forward and complete the study.</span>
    </div>
  )
}

export default React.memo(ProgressMenu)
