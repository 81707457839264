import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface PlayerInfo {
  playerId: string,
  isReady: boolean
}

export interface RoomInfo {
  roomId: string
  players: PlayerInfo[]
}

const initialState: RoomInfo = {
  roomId: "",
  players: []
}

const roomInfoSlice = createSlice({
  name: 'roomInfo',
  initialState,
  reducers: {
    setRoomInfo: (state, action: PayloadAction<RoomInfo>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setRoomInfo } = roomInfoSlice.actions

export default roomInfoSlice.reducer