import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ItCard {
  key: string;
  imageUrl: string;
  definitions?: string[];
}

const initialState: ItCard = {
  key: "",
  imageUrl: "",
};

const itCardSlice = createSlice({
  name: "itCard",
  initialState,
  reducers: {
    setItCard: (state, action: PayloadAction<ItCard>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setItCard } = itCardSlice.actions;

export default itCardSlice.reducer;
