import React from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import StoreMenu from "components/Store/StoreMenu";
import StoreItems from "components/Store/StoreItems";
import { useNavigate } from "react-router-dom";

export default function Store() {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate("/");
  };

  return (
    <div className="MainContainer">
      <div className="Profile_content">
        <Paper>
          <Box className="h-fit p-10" sx={{ flexGrow: 1 }}>
            <Typography sx={{ flex: "1 1 100%" }} variant="h4" className="py-5">
              Store
            </Typography>
            <Container className="flex w-screen py-10" maxWidth={false}>
              <Grid
                container
                spacing={{ md: 3 }}
                columns={{ xs: 4, sm: 6, md: 12 }}
                className="rounded-lg grid-flow-row auto-rows-fr"
              >
                <Grid item xs={1} sm={2} md={3}>
                  <StoreMenu />
                </Grid>
                <Grid item xs={3} sm={4} md={9}>
                  <StoreItems title="Avatars" />
                </Grid>
              </Grid>
            </Container>
            <a
              className="AuthButton btn btn-primary"
              style={{ padding: "1em" }}
              onClick={redirectToHome}
            >
              Back to main menu
            </a>
          </Box>
        </Paper>
      </div>
    </div>
  );
}
