import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch } from "@reduxjs/toolkit";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  LeaderboardInfo,
  Player,
  setLeaderboard,
} from "store/slices/leaderboardSlice";
import api from "utils/api";
import CurrentPlayer from "./CurrentPlayer";
import { setErrorMessage } from "store/slices/errorMessageSlice";
import { sendingRequest } from "store/slices/sendingRequestSlice";
import { getAccessToken } from "utils/auth";
import { GameSocket, useWebSocket } from "utils/WebSocketProvider";

type Order = "asc" | "desc";

interface HeadCell {
  field: keyof Player;
  label: string;
  numeric: boolean;
  sort: boolean;
}

const headCells: HeadCell[] = [
  { field: "position", label: "Position", numeric: true, sort: true },
  { field: "player", label: "Player", numeric: false, sort: true },
  { field: "level", label: "Level", numeric: true, sort: true },
  { field: "rankTier", label: "Rank Tier", numeric: false, sort: true },
  // { field: "badge", label: "Badge", numeric: false, sort: false },
  { field: "score", label: "Score", numeric: false, sort: true },
  // { field: "addFriend", label: "Add Friend", numeric: false, sort: false },
];

export const getLeaderboard = (socket: GameSocket) => {
  return (dispatch: Dispatch) => {
    dispatch(setErrorMessage(""));
    
    socket?.emit("leaderboard", ({ me, players }: LeaderboardInfo) => {
      dispatch(setLeaderboard({
        me,
        players: players.map((player: Player) => ({
                position: player.position,
                rankTier: player.rankTier,
                player: player.player,
                level: player.level,
                score: player.score,
                // badge: player.badge,
                // addFriend: player.addFriend
              }))
      }));
    });

    // api
    //   .get("/api/leaderboard", {
    //     headers: {
    //       Authorization: `Bearer ${getAccessToken()}`,
    //     },
    //   })
    //   .then((data) => {
    //     const leaderboard: LeaderboardInfo = {
    //       me: data.data.me as Player,
    //       players: [],
    //     };

    //     data.data.players.map((player: Player) => {
    //       leaderboard.players.push({
    //         position: player.position,
    //         rankTier: player.rankTier,
    //         player: player.player,
    //         level: player.level,
    //         score: player.score,
    //         // badge: player.badge,
    //         // addFriend: player.addFriend
    //       });
    //     });

    //     dispatch(setLeaderboard(leaderboard));
    //   })
    //   .catch((error) => {
    //     if (error.response) {
    //       dispatch(setErrorMessage(error.response.statusText));
    //     }
    //   })
    //   .finally(() => {
    //     dispatch(sendingRequest(false));
    //   });
  };
};

export default function Leaderboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const socket = useWebSocket();

  const leaderboard = useAppSelector((state) => state.leaderboard.leaderboard);

  const [filteredPlayers, setFilteredPlayers] = useState([
    ...leaderboard.players,
  ]);
  const [order, setOrder] = useState("asc" as Order);
  const [orderBy, setOrderBy] = useState<keyof Player>("position");

  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(8)

  const redirectToHome = () => {
    navigate("/");
  };

  useEffect(() => {
    socket && dispatch(getLeaderboard(socket));
  }, [socket]);

  useEffect(() => {
    setFilteredPlayers([...leaderboard.players]);
  }, [leaderboard]);

  const filterPlayers = (
    e: SyntheticEvent<Element, Event> | undefined,
    rankTier: string | null
  ) => {
    if (rankTier === null) {
      setFilteredPlayers(leaderboard.players.slice(0, 10));
    } else {
      setFilteredPlayers(
        leaderboard.players
          .slice(0, 10)
          .filter((player) => player.rankTier === rankTier)
      );
    }
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(
    order: string,
    orderBy: string | number | symbol
  ): (a: any, b: any) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Player
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Player) => (
    event: React.MouseEvent<unknown>
  ) => {
    handleRequestSort(event, property);
  };

  // const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
  //   setPage(newPage)
  // }
  //
  // const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(parseInt(event.target.value, 10))
  //   setPage(0)
  // }

  const addFriend = (player: string) => {
    // TODO: implement add player functionality
  };

  return (
    <div className="MainContainer">
      <div className="Leaderboard_content">
        <Paper>
          <TableContainer className="max-h-[90vh]">
            <Toolbar>
              <Typography sx={{ flex: "1 1 100%" }} variant="h4">
                Leaderboard
              </Typography>
            </Toolbar>
            <CurrentPlayer />
            <Table aria-label="simple table" stickyHeader={false}>
              <TableHead>
                <TableRow>
                  {headCells.map(({ field, label, sort }) => (
                    <TableCell
                      key={field}
                      align="center"
                      sortDirection={orderBy === field && sort ? order : false}
                    >
                      {sort ? (
                        <TableSortLabel
                          active={orderBy === field}
                          direction={orderBy === field ? order : "asc"}
                          onClick={createSortHandler(field)}
                        >
                          {" "}
                          {label}
                        </TableSortLabel>
                      ) : (
                        label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPlayers.length === 0 ? (
                  <TableRow sx={{ height: "10rem" }}>
                    <TableCell
                      colSpan={headCells.length}
                      className="h-2 text-center"
                    >
                      No players available.
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredPlayers
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .sort(getComparator(order, orderBy))
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{row.position}</TableCell>
                        <TableCell align="center">{row.player}</TableCell>
                        <TableCell align="center">{row.level}</TableCell>
                        <TableCell align="center">{row.rankTier}</TableCell>
                        {/*<TableCell align="center">{row.badge}</TableCell>*/}
                        <TableCell align="center">{row.score}</TableCell>
                        {/*<TableCell align="center"><Button className="!bg-sky-500	!text-whiteSnow rounded-lg" onClick={() => addFriend(row.player)}>Add friend</Button></TableCell>*/}
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/*<TablePagination*/}
          {/*  rowsPerPageOptions={[10]}*/}
          {/*  component="div"*/}
          {/*  count={filteredPlayers.length}*/}
          {/*  rowsPerPage={10}*/}
          {/*  page={page}*/}
          {/*  onPageChange={handleChangePage}*/}
          {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
          {/*/>*/}
        </Paper>
        <div className="py-4"></div>
        <Box>
          <a
            className="AuthButton btn btn-primary"
            style={{ padding: "1em" }}
            onClick={redirectToHome}
          >
            Back to main menu
          </a>
        </Box>
      </div>
    </div>
  );
}
