import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Level {
  level: number;
  baseExp: number;
  topExp: number;
}

export interface CurrencyChange {
  current: number;
  increase: number;
}

export interface ExpChange {
  current: number;
  increase: number;
}

export interface LevelChange {
  current: number;
  increase: number;
  levels: Level[];
}

export interface GainedExp {
  won: boolean;
  bonus: number;
  level: LevelChange;
  exp: ExpChange;
  currency: CurrencyChange;
}

const initialState: GainedExp = {
  won: false,
  bonus: 0,
  level: { current: 0, increase: 0, levels: [] },
  exp: { current: 0, increase: 0 },
  currency: { current: 0, increase: 0 },
};

const gainedExpSlice = createSlice({
  name: "gainedExp",
  initialState,
  reducers: {
    setGainedExp: (state, action: PayloadAction<GainedExp>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGainedExp } = gainedExpSlice.actions;

export default gainedExpSlice.reducer;
