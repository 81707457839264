import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { login } from 'utils/auth'
import MyInput from 'components/Input'
import LoadingView from 'components/LoadingView'
import ErrorView from 'components/ErrorView'

export default function LoginForm() {
  const currentlySending = useAppSelector(state => state.currentlySending)
  const errorMessage = useAppSelector(state => state.errorMessage)
  const authForm = useAppSelector(state => state.authForm)
  const dispatch = useAppDispatch()

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault()
    dispatch(login(authForm.username, authForm.password))
  }

  const [showPass, setShowPass] = useState(false)

  const handleClickShowPassword = () => {
    setShowPass(!showPass)
  }

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault()
  }

  return (
    <form onSubmit={submitForm} className="flex flex-col gap-6">
      <MyInput type="text" name="username" model="username" placeholder="Username" className="mb-6 text-center" autofocus />
      <MyInput id="current-password" type={showPass ? 'text' : 'password'} name="current-password" model="password"
        placeholder="Password" className="mb-6" endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}>
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        } />
      <input type="submit" value="Login" className="mb-4 AuthButton btn btn-primary" />
      <LoadingView currentlySending={currentlySending} />
      <ErrorView message={errorMessage} />
    </form>
  )
}
