import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import DifficultyChooser from 'components/Home/DifficultyChooser';
import { difficulties, REFRESH_INTERVAL, unavailableDifficulties } from 'constants/AppConstants';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StaticDialog } from 'react-st-modal';
import { useAppSelector } from 'store/hooks';
import { PlayerInfo } from 'store/slices/roomInfoSlice';
import { useInterval } from 'usehooks-ts';
import { getRemainingTasks } from 'utils/utils';
import { useWebSocket } from 'utils/WebSocketProvider';

type Order = 'asc' | 'desc';

export interface LobbyRoomInfo {
  roomId: string;
  playerCount: number;
  difficulty: string;
}

type Data = LobbyRoomInfo & {
  join: any;
};

type Players = PlayerInfo[];

interface HeadCell {
  field: keyof Data;
  label: string;
  numeric: boolean;
  sort: boolean;
}

const headCells: HeadCell[] = [
  { field: 'roomId', label: 'Room ID', numeric: true, sort: true },
  { field: 'playerCount', label: 'Players', numeric: true, sort: true },
  { field: 'difficulty', label: 'Difficulty', numeric: false, sort: true },
  { field: 'join', label: 'Join room', numeric: false, sort: false },
];

export default function GameSelection() {
  const navigate = useNavigate();

  const progress = useAppSelector((state) => state.progress);
  const [difficultyMenuOpen, setDifficultyMenuOpen] = useState(false);
  const socket = useWebSocket();

  const [games, setGames] = useState<LobbyRoomInfo[]>([]);
  const [filteredGames, setFilteredGames] = useState<LobbyRoomInfo[]>([]);

  var [remainingTasks, taskCompletion] = getRemainingTasks(progress);
  const difficultyFilterValues = difficulties.map((difficulty) => difficulty.id.toUpperCase());
  const [difficultyFilter, setdifficultyFilter] = useState<string>('ANY');
  const [order, setOrder] = useState('asc' as Order);
  const [orderBy, setOrderBy] = useState<keyof LobbyRoomInfo>('playerCount');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const startGame = () => {
    // if (remainingTasks <= 0) {
    setDifficultyMenuOpen(true);
    // } else {
    // ! commented out this logic for now, since we don't need to do crowdsourcing.
    // if all easy are done
    // if (taskCompletion['EASY'] < 3) {
    //   handleDiffChooser(Difficulty.EASY)
    // }
    // else {
    //   handleDiffChooser(Difficulty.MEDIUM)
    // }
    // }
  };

  const handleDiffChooser = (result: number | undefined) => {
    setDifficultyMenuOpen(false);

    if (result !== undefined && result !== null) {
      // Difficulty[result] returns the string name of the enum instead of the index
      socket?.emit('create', difficulties[result].id);
      // dispatch(setDifficulty(result))
      // navigate('/lobby')
    }
  };

  const redirectToHome = () => {
    navigate('/');
  };

  const filterGames = (difficulty: string | null) => {
    if (difficulty === null || difficulty === 'ANY') {
      setdifficultyFilter('ANY');
      setFilteredGames(games);
    } else {
      setdifficultyFilter(difficulty);
      setFilteredGames(games.filter((game) => game.difficulty.toString().toUpperCase() == difficulty));
    }
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof LobbyRoomInfo>(
    order: Order,
    orderBy: Key,
  ): (a: LobbyRoomInfo, b: LobbyRoomInfo) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof LobbyRoomInfo) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    if (property == 'join') return;
    handleRequestSort(event, property);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const joinGame = (roomId: string) => {
    socket?.emit('join', roomId, (response: any) => {
      // if (response !== true) {
      navigate(`/room-${roomId}`);
      // } else {
      //   toast.error("The room is full or doesn't exist, please try joining another room")
      //   navigate('/game_selection')
      // }
    });
  };

  const loadGames = () => {
    socket?.emit('gameList', (games: LobbyRoomInfo[]) => {
      setGames(games);
    });
  };

  useInterval(loadGames, REFRESH_INTERVAL);

  useEffect(() => {
    socket?.emit('inActiveGame', (isInActiveGame: boolean) => {
      if (isInActiveGame) navigate('/play');
    });
    loadGames();
  }, [socket]);

  useEffect(() => {
    filterGames(difficultyFilter);
  }, [games]);

  return (
    <div className="MainContainer">
      <div className="GameSelection_content">
        <Paper>
          <Grid container className="items-center justify-around pt-4">
            {/* <Grid item> */}
            <FormControl variant="outlined" size="small">
              <InputLabel id="difficulty-label">Difficulty</InputLabel>
              <Select
                labelId="difficulty-label"
                id="difficulty-select"
                value={difficultyFilter}
                onChange={(e) => filterGames(e.target.value)}
                label="Difficulty"
              >
                {difficulties.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id.toUpperCase()}
                    disabled={!!unavailableDifficulties.find((x) => x.toUpperCase() === option.id.toUpperCase())}
                  >
                    {option.id.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* </Grid> */}
            {/* <Grid item xs> */}
            <Typography align="center" variant="h6">
              Available games
            </Typography>
            {/* </Grid> */}
            {/* <Grid item> */}
            <Button size="medium" variant="outlined" onClick={startGame} className="mb-1 DefaultButton text-large">
              New game
            </Button>
            {/* </Grid> */}
          </Grid>
          <TableContainer className="max-h-[90vh] flex flex-col">
            <Table className="flex-auto w-fit" aria-label="simple table" stickyHeader={false}>
              <TableHead>
                <TableRow>
                  {headCells.map(({ field, label, sort }) => (
                    <TableCell key={field} align="center" sortDirection={orderBy === field && sort ? order : false}>
                      {sort ? (
                        <TableSortLabel
                          active={orderBy === field}
                          direction={orderBy === field ? order : 'asc'}
                          onClick={createSortHandler(field)}
                        >
                          {' '}
                          {label}
                        </TableSortLabel>
                      ) : (
                        label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredGames.length === 0 ? (
                  <TableRow sx={{ height: '10rem' }}>
                    <TableCell colSpan={headCells.length} className="h-2 text-center">
                      No games available. Please create a new game.
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredGames
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .sort(getComparator(order, orderBy))
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{row.roomId}</TableCell>
                        <TableCell align="center">{row.playerCount}/2</TableCell>
                        <TableCell align="center">{row.difficulty.toUpperCase()}</TableCell>
                        <TableCell align="center">
                          <Button onClick={() => joinGame(row.roomId)}>Join Game</Button>
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[8]}
            component="div"
            count={filteredGames.length}
            rowsPerPage={8}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <div className="py-4"></div>
        <Box className="self-center">
          <a className="AuthButton btn btn-primary" style={{ padding: '1em' }} onClick={redirectToHome}>
            Back to main menu
          </a>
        </Box>
        <StaticDialog
          isOpen={difficultyMenuOpen}
          title="Choose a difficulty"
          onAfterClose={handleDiffChooser}
          showCloseIcon
        >
          <DifficultyChooser />
        </StaticDialog>
      </div>
    </div>
  );
}
