import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: boolean = false

const instructionShownSlice = createSlice({
  name: 'instructionShown',
  initialState,
  reducers: {
    setInstructionShown: (state, action: PayloadAction<boolean>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setInstructionShown } = instructionShownSlice.actions

export default instructionShownSlice.reducer