import { Button, ButtonGroup } from '@mui/material';
import { difficulties } from 'constants/AppConstants';
import React from 'react';
import { useDialog } from 'react-st-modal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setDifficulty } from 'store/slices/difficultySlice';
import { capitalize } from 'utils/utils';

const DifficultyChooser = () => {
  const dialog = useDialog();
  const dispatch = useAppDispatch();
  const playerCount = useAppSelector((state) => state.onlineCount);
  const difficulty = useAppSelector((state) => state.gameDifficulty);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dialog.close(difficulty);
  };

  const setSelectedBtn = (difficulty: number) => {
    dispatch(setDifficulty(difficulty));
  };

  return (
    <div id="DifficultyChooser">
      <form noValidate autoComplete="off" id="difficultyForm" onSubmit={handleSubmit}>
        <ButtonGroup
          aria-label="Difficulty chooser"
          size="medium"
          color="primary"
          orientation="vertical"
          style={{ gridArea: 'difficulty' }}
          disableElevation
        >
          {difficulties.map((value, index) => {
            return (
              <Button
                key={value.id}
                id={value.id}
                onClick={() => setSelectedBtn(index)}
                variant={difficulty === index ? 'contained' : 'outlined'}
              >
                {value.id.toUpperCase()}
              </Button>
            );
          })}
        </ButtonGroup>

        <div id="DifficultyInfo" style={{ gridArea: 'description', width: '100%', alignSelf: 'start' }}>
          <h3 className="text-larger text-bold" style={{ textAlign: 'center' }}>
            {capitalize(difficulties[difficulty].id)}
          </h3>
          <span>
            Number of cards: <b>{difficulties[difficulty].numCards}</b>
          </span>
          {/* <span>Players waiting: <b>{difficulty === difficulties.length - 1
            ? playerCount.waiting.reduce((a, b) => a + b, 0)
            : playerCount.waiting[difficulty]}</b></span> */}
          <span>{difficulties[difficulty].description}</span>
        </div>

        <Button
          color="primary"
          variant="contained"
          type="submit"
          form="difficultyForm"
          id="difficultySubmit"
          disableElevation
          // disabled={difficulty === 2 ? true : false}
          style={{ gridArea: 'confirm', width: '30%' }}
        >
          Generate game
        </Button>
      </form>
    </div>
  );
};

export default React.memo(DifficultyChooser);
