import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: string = ''

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<string>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSession } = sessionSlice.actions

export default sessionSlice.reducer