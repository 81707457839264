import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: number[] = []

const reportCheckedSlice = createSlice({
  name: 'reportChecked',
  initialState,
  reducers: {
    setReportChecked: (state, action: PayloadAction<number | null>) => {
      // reset report checked if passed in null
      const index = action.payload
      if (index === null) {
        return state = []
        return
      }

      const currentIndex = state.indexOf(index)
      const newChecked = [...state]

      if (currentIndex === -1) {
        newChecked.push(index)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      return state = newChecked
    }
  },
})

// Action creators are generated for each case reducer function
export const { setReportChecked } = reportCheckedSlice.actions

export default reportCheckedSlice.reducer