import { Button } from "@mui/material"
import BackButton from "components/Game/ActionBar/BackButton"
import { STAGE_ASKER_FLIP } from "constants/AppConstants"
import React, { Fragment } from "react"
import toast from "react-hot-toast"
import { useAppSelector } from "store/hooks"
import { removeUnderscore, simplifyBoard } from "utils/utils"
import { useWebSocket } from "utils/WebSocketProvider"

export default function GuessBar() {
  const cards = useAppSelector((state) => state.game.cards);
  const guess = useAppSelector((state) => state.game.guess);
  const stage = useAppSelector((state) => state.game.stage);

  const socket = useWebSocket();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // TODO check if this is correct
    if (guess === -1) {
      toast.error("Please choose a card!", { id: "error" });
      return false;
    }

    socket?.emit("makeGuess", {
      guessCard: cards[guess].key,
      board: stage === STAGE_ASKER_FLIP ? simplifyBoard(cards) : [],
    });
  };

  return (
    <Fragment>
      <form
        noValidate
        autoComplete="off"
        id="guessForm"
        onSubmit={handleSubmit}
      >
        {guess === -1 ? (
          <span id="guessText text-italic color-grey">
            <i className="text-disabled">Choose a card from the board...</i>
          </span>
        ) : (
          <Fragment>
            <span id="guessText">You've chosen</span>
            <span id="guessName">{removeUnderscore(cards[guess].key)}</span>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              form="guessForm"
              id="guessSubmit"
              disableElevation
            >
              Confirm
            </Button>
          </Fragment>
        )}
      </form>
      <BackButton />
    </Fragment>
  );
}
