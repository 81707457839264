import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  Box,
  Divider,
  Grid,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { loadMe } from "utils/auth";
import Avataar from "components/Avatars/Avatars";

export default function CurrentPlayer() {
  const dispatch = useAppDispatch();

  const me = useAppSelector((state) => state.game.me);
  const currentUser = useAppSelector(
    (state) => state.leaderboard.leaderboard.me
  );

  useEffect(() => {
    dispatch(loadMe());
  }, []);

  return (
    <Table>
      <TableRow className="flex h-20 w-auto bg-sky-600 drop-shadow-2xl">
        <TableCell className="h-auto w-fit self-center">
          <Avataar
            topType="LongHairStraight"
            accessoriesType="Kurt"
            hairColor="PastelPink"
            facialHairType="Blank"
            clotheType="BlazerShirt"
            eyeType="Default"
            eyebrowType="Default"
            mouthType="Default"
            skinColor="Light"
            className="h-16 w-fit self-center"
          />
        </TableCell>
        <TableCell
          sx={{
            borderRight: "1px solid white",
          }}
          className="h-auto w-fit self-start"
        >
          <Typography className="text-center" variant="h5">
            {me}
          </Typography>
          <Typography variant="subtitle1" className="text-center">
            <EmojiEventsIcon sx={{ mb: 0.5 }} /> #{currentUser.position}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            borderRight: "1px solid white",
          }}
          className="h-auto w-fit self-start"
        >
          <Typography className="text-center" variant="h5">
            Level
          </Typography>
          <Typography variant="subtitle1" className="text-center">
            <SignalCellularAltIcon fontSize="inherit" sx={{ mb: 0.5 }} />
            {currentUser.level}
          </Typography>
        </TableCell>
        <TableCell
          sx={{
            borderRight: "1px solid white",
          }}
          className="h-auto self-start"
        >
          <Typography className="text-center" variant="h5">
            Rank Tier
          </Typography>
          <Typography variant="subtitle1" className="text-center">
            {currentUser.rankTier}
          </Typography>
        </TableCell>
        <TableCell className="h-auto w-fit self-start">
          <Typography className="text-center" variant="h5">
            Score
          </Typography>
          <Typography variant="subtitle1" className="text-center">
            {currentUser.score}
          </Typography>
        </TableCell>
      </TableRow>
    </Table>
  );
}
