import { useEffect } from "react"
import { useNavigate } from "react-router"
import { useAppDispatch } from "store/hooks"
import { changeAuth } from "store/slices/authFormSlice"
import api from "utils/api"
import { useQuery } from "utils/utils"

export default function ProlificEntry() {
  const query = useQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const pid = query.get("PROLIFIC_PID")

    if (pid === undefined || pid === null || pid === "") {
      navigate('/register', { replace: true })
    } else {
      api.get('/api/is_registered', {
        params: {
          pid: pid
        }
      })
        .then(data => {
          // if already in game, don't show new game selection
          if (data.data.is_registered) {
            // proceed to main menu or login
            dispatch(changeAuth({ username: data.data.username }))
            navigate('/login', { replace: true, state: { pid: pid } })
          } else {
            // proceed to registration screen
            // dispatch(changeForm({ username:  }))
            navigate('/register', { replace: true, state: { pid: pid } })
          }
        })
    }

  }, [])

  return (<div></div>)
}