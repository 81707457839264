import ProgressMenu from "components/Prolific/ProgressMenu";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { StaticDialog } from "react-st-modal";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useWebSocket } from "utils/WebSocketProvider";
import { loadMe, refreshProgress } from "utils/auth";
import { getRemainingTasks } from "utils/utils";

const HomeView = () => {
  const socket = useWebSocket();
  const dispatch = useAppDispatch();
  const playerCount = useAppSelector((state) => state.onlineCount);
  const me = useAppSelector((state) => state.game.me);
  const progress = useAppSelector((state) => state.progress);
  const [progressMenuOpen, setProgressMenuOpen] = useState(false);
  const navigate = useNavigate();

  var [remainingTasks, taskCompletion] = getRemainingTasks(progress);

  useEffect(() => {
    dispatch(loadMe());
    // dispatch(refreshProgress());
  }, [socket]);

  const handleProgress = () => {
    setProgressMenuOpen(false);
  };

  const handleOpenProgress = () => {
    dispatch(refreshProgress());
    setProgressMenuOpen(true);
  };

  return (
    <div className="MainMenu">
      <StaticDialog
        isOpen={progressMenuOpen}
        title="Task progress"
        onAfterClose={handleProgress}
        showCloseIcon
      >
        <ProgressMenu />
      </StaticDialog>

      <div className="text-center MainMenu_content" style={{ height: "100vh" }}>
        <div style={{ flexGrow: "2" }}></div>
        <img
          src="public/logo-big.png"
          alt="logo"
          className="mb-12 AuthCard_body_logo"
        />

        <span className="mb-1 text-larger">Welcome {me}!</span>
        <div style={{ flexGrow: "1" }}></div>
        {/* {Object.keys(progress).length !== 0 ?
          <MLink onClick={handleOpenProgress} color="inherit">You have
            {remainingTasks === 0 ? ' completed all tasks!' : <Fragment><span className="color-accent">{remainingTasks}</span> unfinished tasks.</Fragment>}
        </MLink> : null} */}
        {/* <div className="mb-6"></div> */}
        <Link to="/game_selection" className="mb-8 MenuButton text-large">
          Start Game
        </Link>
        <Link to="/leaderboard" className="mb-8 MenuButton text-large">
          Leaderboard
        </Link>
        <Link to="/profile" className="MenuButton mb-8 text-large">
          Profile
        </Link>
        {/* <Link to="/store" className="MenuButton mb-8 text-large">
          Store
        </Link> */}
        <div style={{ flexGrow: "1" }}></div>
        <Link
          to="/logout"
          className="mb-8 MenuButton MenuButton--auth text-large"
        >
          Logout
        </Link>
        <div style={{ flexGrow: "2" }}></div>
        <span className="mb-4 DisclaimerHome">
          FindItOut was designed for research purposes. <br />
          {/* Please take a couple minutes after playing the game to fill out this <Link to="/questionnaire" style={{ fontSize: "1.1em" }}>questionnaire</Link>.</span> */}
          {/* You can access the research paper <Link to={{ pathname: "http://resolver.tudelft.nl/uuid:b2645fff-49ca-4392-b5e9-3794fa9fca19" }} style={{ fontSize: "1.1em" }} target="_blank">here</Link>. */}
        </span>
        <span className="mb-4 DisclaimerHome">
          In case of bugs, problems or recommendations, please contact{" "}
          <a href="mailto:A.Hu-4@student.tudelft.nl">
            A.Hu-4@student.tudelft.nl
          </a>
        </span>
      </div>
    </div>
  );
};

export default HomeView;
