import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: string = "";

const meSlice = createSlice({
  name: "me",
  initialState,
  reducers: {
    setMe: (state, action: PayloadAction<string>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMe } = meSlice.actions;

export default meSlice.reducer;
