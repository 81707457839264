
export default function useLegacySound(sourceFile: string, volume = 1) {
  // const [cookies] = useCookies([AUDIO_ENABLED])
  const sound = new Audio(sourceFile)
  sound.volume = volume

  const playSound = () => {
    // sound.muted = cookies[AUDIO_ENABLED] !== 'true'
    sound.play()
  }

  return playSound
}