import { FormControl, Input, InputBaseProps, InputProps } from '@mui/material'
import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { changeAuth } from 'store/slices/authFormSlice'

export interface MyInputProps {
  model: 'password' | 'username',
  autofocus?: boolean
}

export default function MyInput({ id, className, type, name, model, placeholder, autofocus, ...props }: MyInputProps & InputBaseProps) {
  const authForm = useAppSelector(state => state.authForm)
  const dispatch = useAppDispatch()

  const onChangeInput = useCallback(
    ({ target: { name, value } }) => {
      // temp workaround
      if (name.includes("password")) {
        name = "password"
      }
      dispatch(changeAuth({ [name]: value }))
    },
    [],
  )

  return (
    <FormControl className={`InputBox ${className || ""}`}>
      {/* <InputLabel htmlFor={id}>{placeholder}</InputLabel> */}
      <Input id={id} className="InputBox_input" type={type} name={name}
        value={authForm[model]} autoCapitalize='none' required placeholder={placeholder}
        onChange={onChangeInput} autoFocus={autofocus ? true : false} color="secondary" {...props} />
    </FormControl>
  )
}
