import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: number = -1

const guessCardSlice = createSlice({
  name: 'guessCard',
  initialState,
  reducers: {
    setGuessCard: (state, action: PayloadAction<number>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setGuessCard } = guessCardSlice.actions

export default guessCardSlice.reducer