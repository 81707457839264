import { Tooltip } from '@mui/material';
import cardFlipSound from 'audio/f4ngy_card_flip.wav';
import {
  ACTION_GUESS,
  ACTION_NONE,
  STAGE_ASKER_ASK_GUESS,
  STAGE_ASKER_FLIP,
  STAGE_BONUS,
} from 'constants/AppConstants';
import Image from 'mui-image';
import React, { Fragment } from 'react';
import ReactCardFlip from 'react-card-flip';
import toast from 'react-hot-toast';
import { Textfit } from 'react-textfit';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { flipCard } from 'store/slices/cardsSlice';
import { setGuessCard } from 'store/slices/guessCardSlice';
import { ViewMode } from 'store/slices/viewModeSlice';
import useCustomSound from 'utils/useCustomSound';
import { capitalize } from 'utils/utils';

export interface CardProps {
  imageLink: string;
  name: string;
  definitions?: string[];
  index?: number;
  unflippable?: boolean;
  showName?: boolean;
  gameover?: boolean;
}

function Card({
  imageLink,
  name,
  definitions,
  index,
  unflippable,
  showName = true,
  gameover,
  className,
  ...props
}: CardProps & { className?: string }) {
  const card = index !== undefined ? useAppSelector((state) => state.game.cards[index]) : null;
  const isTurn = useAppSelector((state) => state.game.myTurn);
  const stage = useAppSelector((state) => state.game.stage);
  const action = useAppSelector((state) => state.game.action);
  const guess = useAppSelector((state) => state.game.guess);
  const viewMode = useAppSelector((state) => state.game.viewMode);

  const formattedName = capitalize(name.replaceAll('_', ' '));

  const dispatch = useAppDispatch();
  const playFlip = useCustomSound(cardFlipSound, 0.4);

  const defDiv = (
    <div>
      {(viewMode == ViewMode.BOTH || viewMode == ViewMode.IMAGE) && (
        <div className="Card" style={{ aspectRatio: '1' }}>
          <Image
            src={imageLink}
            alt={name}
            showLoading
            bgColor="transparent"
            style={{ borderRadius: '0.5rem', aspectRatio: '1' }}
            duration={0.5}
          />
        </div>
      )}
      {(viewMode == ViewMode.BOTH || viewMode == ViewMode.TEXT) && (
        <div className="Definitions">
          <span className="Definitions-Title">{name.replaceAll('_', ' ')}</span>
          <ol className="Definitions-List">
            {definitions && definitions.map((definition, index) => <li key={index}>{capitalize(definition)}</li>)}
          </ol>
        </div>
      )}
    </div>
  );

  // Flips the card on click to reveal the answer
  const handleClick = () => {
    if (unflippable || index === undefined) return;

    if (!isTurn) {
      toast.error('Not your turn yet', { id: 'error' });
      return;
    }

    if (stage === STAGE_ASKER_FLIP && action === ACTION_NONE) {
      playFlip();
      dispatch(flipCard(index));
      return;
    }

    if (
      (action === ACTION_GUESS && [STAGE_ASKER_ASK_GUESS, STAGE_ASKER_FLIP].includes(stage)) ||
      stage === STAGE_BONUS
    ) {
      if (index === guess) {
        dispatch(setGuessCard(-1));
      } else {
        dispatch(setGuessCard(index));
      }
      return;
    }

    toast.error('Not allowed to flip cards right now.', { id: 'error' });
  };

  return (
    <Tooltip title={defDiv} arrow>
      <div
        className={`CardWrapper ${guess === index ? 'Card-selected' : ''}${gameover ? 'Card-gameover' : ''} ${
          className || ''
        }`}
      >
        {unflippable === true || card !== null ? (
          <Fragment>
            <ReactCardFlip isFlipped={unflippable ? false : card !== null && card.isFlipped && guess !== index}>
              <div className="Card Card_front" onClick={handleClick} style={{ aspectRatio: '1' }}>
                {viewMode == ViewMode.TEXT ? (
                  <div className="Square">
                    <Textfit className="Square-Content">{formattedName}</Textfit>
                  </div>
                ) : (
                  <Image
                    src={imageLink}
                    alt={name}
                    showLoading
                    bgColor="transparent"
                    style={{ borderRadius: '0.5rem', aspectRatio: '1' }}
                  />
                )}
              </div>
              <div className="Card Card_back" onClick={handleClick}>
                <Image src="public/mini_logo.png" alt="finditout logo icon" />
              </div>
            </ReactCardFlip>
            {/* {(unflippable || (showName && (viewMode === ViewMode.BOTH || (card && card.isFlipped)))) &&  */}
            {viewMode === ViewMode.BOTH && (unflippable || showName) && (
              <span className="Card_caption">{formattedName}</span>
            )}
          </Fragment>
        ) : (
          'Loading card'
        )}
      </div>
    </Tooltip>
  );
}

export default React.memo(Card);
