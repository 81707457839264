import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = 0;

const difficultySlice = createSlice({
  name: 'difficulty',
  initialState,
  reducers: {
    setDifficulty: (state, action: PayloadAction<number>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDifficulty } = difficultySlice.actions;

export default difficultySlice.reducer;
