import { LobbyRoomInfo } from 'components/GameSelection/GameSelection';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-st-modal';
import io, { Socket } from 'socket.io-client';
import { useAppDispatch } from 'store/hooks';
import { setAnswer } from 'store/slices/answerSlice';
import { Card, setCards } from 'store/slices/cardsSlice';
import { setForfeited } from 'store/slices/forfeitedSlice';
import { GainedExp, setGainedExp } from 'store/slices/gainedExpSlice';
import { setGameover } from 'store/slices/gameoverSlice';
import { ItCard, setItCard } from 'store/slices/itCardSlice';
import { LeaderboardInfo } from 'store/slices/leaderboardSlice';
import { setMe } from 'store/slices/meSlice';
import { setMyTurn } from 'store/slices/myTurnSlice';
import { setOpponentItCard } from 'store/slices/opponentItCardSlice';
import { setOpponent } from 'store/slices/opponentSlice';
import { Question, setQuestionHistory } from 'store/slices/questionHistorySlice';
import { setQuestion } from 'store/slices/questionSlice';
import { Relation, setRelations } from 'store/slices/relationSlice';
import { setRole } from 'store/slices/roleSlice';
import { RoomInfo, setRoomInfo } from 'store/slices/roomInfoSlice';
import { setSession } from 'store/slices/sessionSlice';
import { ShopInfo } from 'store/slices/shopSlice';
import { setStage } from 'store/slices/stageSlice';
import bellsSound from '../audio/bells.mp3';
import failSound from '../audio/fail.mp3';
import successSound from '../audio/success.mp3';
import { SERVER_URL } from '../constants/AppConstants';
import { getAccessToken, loadOnlinePlayers, refreshToken } from './auth';
import useLegacySound from './useLegacySound';
import { SimplifiedBoard } from './utils';

export type Experience = {
  score: bigint;
  level: number;
};

export type Currency = {
  coin: number;
};

export type Inventory = {
  [itemId: string]: number;
};

export type GenericResponse = {
  success: boolean;
  message: string;
};

export type BuyHintRequest = {
  type: string;
  amount: number;
};

interface ServerToClientEvents {
  // room
  room: (roomInfo: RoomInfo) => void;
  ready: () => void;
  unready: () => void;
  // game
  session: (gameSession: string) => void;
  turn: (isMyTurn: boolean) => void;
  role: (myRole: string) => void;
  cards: (cards: Card[]) => void;
  itCard: (itCard: ItCard) => void;
  opponentItCard: (itCard: ItCard) => void;
  me: (me: string) => void;
  opponent: (opponent: string) => void;
  start: () => void;
  relations: (relations: Relation[]) => void;
  stage: (stage: string) => void;
  question: (question: string) => void;
  answer: (answer: string) => void;
  questionHistory: (history: Question[]) => void;
  gameover: (forfeited: boolean) => void;
  won: (won: GainedExp) => void;
  leave: (player: string) => void;
  join: (player: string) => void;
  retry: () => void;
  forfeit: () => void;
  bonus: () => void;
  connectFailed: (err: Error) => void;
  error: (err: Error) => void;
  usedCardFlip: (success: boolean) => void;
  playersOnline: () => void;
}

interface ClientToServerEvents {
  // lobby
  gameList: (callback: (games: LobbyRoomInfo[]) => void) => void;
  create: (difficulty: string) => void;
  // room
  join: (roomId: string | undefined, callback: (response: any) => void) => void;
  ready: () => void;
  leave: () => void;
  leaveGame: () => void;
  roomInfo: (callback: (message: string) => void) => void;
  // gameInfo
  currency: (callback: (currency: Currency) => void) => void;
  inventory: (callback: (inventory: Inventory) => void) => void;
  experience: (callback: (experience: Experience) => void) => void;
  hintPrices: (callback: (hintPrices: ShopInfo) => void) => void;
  buyHint: (buyItem: BuyHintRequest, callback: (response: GenericResponse) => void) => void;
  leaderboard: (callback: (leaderboard: LeaderboardInfo) => void) => void;
  // game
  forfeitGame: () => void;
  inActiveGame: (callback: (isInActiveGame: boolean) => void) => void;
  getWholeGame: (timeout: any) => void;
  sendQuestion: (
    question: {
      questionType: string;
      target: string;
    },
    callback: ({ success, message }: { success: boolean; message: string }) => void,
  ) => void;
  usedCardFlip: (callback: (usedFlip: boolean) => void) => void;
  flipCardHint: (callback: (response: { success: boolean; hintCard: number; message: string }) => void) => void;
  endTurn: (
    board: SimplifiedBoard[],
    callback: ({ success, message }: { success: boolean; message: string }) => void,
  ) => void;
  makeGuess: (guess: { guessCard: string; board: SimplifiedBoard[] }) => void;
  replyQuestion: (
    answer: string,
    callback: ({ success, message }: { success: boolean; message: string }) => void,
  ) => void;
  report: (reportChecked: number[], callback: (success: boolean) => void) => void;
}

// let socket: Socket<ServerToClientEvents, ClientToServerEvents> | null

export const WebSocketContext = React.createContext<Socket<ServerToClientEvents, ClientToServerEvents> | null>(null);

const WebSocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const playWon = useLegacySound(successSound, 0.5);
  const playLose = useLegacySound(failSound, 0.35);
  const playTurn = useLegacySound(bellsSound, 0.5);
  const [connection, setConnection] = useState<Socket | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(SERVER_URL, {
      // withCredentials: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 2000,
      reconnectionDelayMax: 5000,
      autoConnect: false,
      transports: ['polling', 'websocket'],
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `${getAccessToken() ? `Bearer ${getAccessToken()}` : ''}`,
          },
        },
      },
    });

    console.log('loading socket once');

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    socket.on('room', (roomInfo: RoomInfo) => {
      dispatch(setRoomInfo(roomInfo));
      console.log(`Joined room ${roomInfo}!`);
      console.log('room:', roomInfo);
      navigate(`/room-${roomInfo.roomId}`, { replace: true });
    });

    // load online players immediately
    socket?.on('playersOnline', () => {
      dispatch(loadOnlinePlayers());
    });

    socket.on('ready', () => {
      console.log(`Player is ready`);
    });

    socket.on('unready', () => {
      console.log(`Player is not ready`);
    });

    socket.on('session', (gameSession) => {
      console.log(`Joined game session ${gameSession}`);
      dispatch(setSession(gameSession));
    });

    socket.on('turn', (isMyTurn) => {
      console.log(isMyTurn ? 'It is my turn now' : 'It is the opponents turn');
      dispatch(setMyTurn(isMyTurn));
      if (isMyTurn) {
        toast.success('It is your turn now!', { id: 'turn', duration: 5000 });
        playTurn();
      }
    });

    socket.on('role', (myRole) => {
      // toast.dismiss('error');
      console.log(`I am the ${myRole}`);
      dispatch(setRole(myRole));
    });

    socket.on('cards', (cards) => {
      console.log('Received set of cards', cards);
      dispatch(setCards(cards));
    });

    socket.on('itCard', (itCard) => {
      console.log('Received my IT card', itCard);
      dispatch(setItCard(itCard));
    });

    socket.on('opponentItCard', (itCard) => {
      console.log("Received opponent's IT card", itCard);
      dispatch(setOpponentItCard(itCard));
    });

    socket.on('me', (me) => {
      console.log(`I am ${me}`);
      dispatch(setMe(me));
    });

    socket.on('opponent', (opponent) => {
      console.log(`Opponent is ${opponent}`);
      dispatch(setOpponent(opponent));
    });

    socket.on('start', () => {
      console.log('Starting game!');
      navigate('/play');
      // fixes bug where game would be stuck at end screen
      dispatch(setGameover(false));
    });

    socket.on('relations', (relations) => {
      console.log('Receiving relations!', relations);
      dispatch(setRelations(relations));
    });

    socket.on('stage', (stage) => {
      console.log(`Current stage is ${stage}`);
      dispatch(setStage(stage));
    });

    socket.on('question', (question) => {
      console.log(`Current question is ${question}`);
      dispatch(setQuestion(question));
    });

    socket.on('answer', (answer) => {
      console.log(`The answer was ${answer}`);
      dispatch(setAnswer(answer));
    });

    socket.on('questionHistory', (history) => {
      console.log(`Updated question history ${history}`);
      dispatch(setQuestionHistory(history));
    });

    socket.on('gameover', (forfeited) => {
      console.log(`The game ended`);
      dispatch(setForfeited(forfeited));
      dispatch(setGameover(true));
    });

    socket.on('won', (won) => {
      console.log(`You won: ${won.won}`);
      console.dir(won);
      dispatch(setGainedExp(won));
      if (won.won == true) {
        console.log(`test ${won.won}`);
        playWon();
      } else {
        playLose();
      }
    });

    socket.on('leave', (player) => {
      toast.dismiss('success');
      toast.error(
        `The opponent '${player}' left the game!\nIf the player does not rejoin, you can forfeit the game from the menu to forcefully end this game session.`,
        { id: 'error', duration: 10000 },
      );
      console.log(`${player} left the game!`);
    });

    socket.on('join', (player) => {
      toast.dismiss('error');
      toast.success(`The opponent '${player}' joined the game!`, {
        id: 'success',
        duration: 5000,
      });
      console.log(`${player} joined the game!`);
    });

    socket.on('retry', () => {
      toast(`Your opponent replied UNCLEAR. Try rewording or changing the question.`, {
        id: 'info',
        duration: 10000,
        icon: '❗',
      });
      console.log(`Retrying round`);
    });

    socket.on('forfeit', () => {
      toast(`Your opponent forfeited the game.`, {
        id: 'info',
        duration: 5000,
        icon: '❗',
      });
      console.log(`Opponent forfeited`);
    });

    socket.on('bonus', () => {
      // toast(`Your opponent guessed your card correctly! You can still take a guess of the opponents card!`, { id: ' info', duration: 10000, icon: '❗' })
      Alert(
        <p>
          Your opponent guessed your card correctly! <br /> You can still take a guess of the opponents card.
        </p>,
        'Bonus guess',
      );
      console.log(`Opponent won. Playing bonus round.`);
    });

    // CONNECTION ERRORS

    socket.on('connect_error', (err) => {
      if (err.message == 'Unauthorized') {
        refreshToken();
      } else {
        toast.error('Encountered an error when connecting to server. Please report this to the developers!', {
          id: 'connection',
          duration: 5000,
        });
      }
    });

    socket.on('connectFailed', (err) => {
      toast.error('Failed to connect to server. Please report this to the developers!', {
        id: 'connection',
        duration: 5000,
      });
    });

    socket.on('error', (err) => {
      console.log(err);
      toast.error('Error happened...', { id: 'error', duration: 5000 });
    });

    socket.connect();

    setConnection(socket);
  }, []);

  return <WebSocketContext.Provider value={connection}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = (): GameSocket | null => {
  const ctx = useContext(WebSocketContext);
  if (ctx === undefined) {
    throw new Error('useWebSocket can only be used inside WebSocketContext');
  }
  return ctx;
};

export type GameSocket = Socket<ServerToClientEvents, ClientToServerEvents>;

export default WebSocketProvider;
