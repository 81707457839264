import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { insertIntoArray } from "utils/insertIntoArray"

export interface Card {
  key: string;
  imageUrl: string;
  definitions: string[];
  isFlipped: boolean;
}

const initialState: Card[] = [];

const cardsSlice = createSlice({
  name: "cards",
  initialState,
  reducers: {
    setCards: (state, action: PayloadAction<Card[]>) => {
      return (state = action.payload);
    },
    flipCard: (state, action: PayloadAction<number>) => {
      const cardIdx = action.payload;
      return (state = insertIntoArray(state, cardIdx, {
        ...state[cardIdx],
        isFlipped: !state[cardIdx].isFlipped,
      }));
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCards, flipCard } = cardsSlice.actions;

export default cardsSlice.reducer;
