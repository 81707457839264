import React from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Avatar from "avataaars";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { AvatarProps } from "components/Avatars/Avatars";

interface StoreItem {
  avatar: AvatarProps;
  unlocked: boolean;
  price: number;
  backgroundColor: string;
}

interface StoreItemsProps {
  title: string;
  items?: StoreItem[];
}

const itemList = [
  {
    avatar: {
      topType: "LongHairStraight",
      accessoriesType: "Kurt",
      hairColor: "PastelPink",
      facialHairType: "Blank",
      clotheType: "BlazerShirt",
      eyeType: "Default",
      eyebrowType: "Default",
      mouthType: "Default",
      skinColor: "Light",
    },
    unlocked: false,
    price: 100,
    backgroundColor: "bg-accentYellow",
  },
  {
    avatar: {
      topType: "LongHairDreads",
      accessoriesType: "Kurt",
      hairColor: "BrownDark",
      facialHairType: "Blank",
      clotheType: "CollarSweater",
      eyeType: "Cry",
      eyebrowType: "UnibrowNatural",
      mouthType: "Tongue",
      skinColor: "Light",
    },
    unlocked: true,
    price: 200,
    backgroundColor: "bg-purple",
  },
  {
    avatar: {
      topType: "Eyepatch",
      accessoriesType: "Prescription02",
      hairColor: "BlondeGolden",
      facialHairType: "MoustacheFancy",
      clotheType: "GraphicShirt",
      eyeType: "Side",
      eyebrowType: "DefaultNatural",
      mouthType: "Serious",
      skinColor: "Light",
    },
    unlocked: true,
    price: 300,
    backgroundColor: "bg-salmonPink",
  },
  {
    avatar: {
      topType: "ShortHairDreads02",
      accessoriesType: "Prescription02",
      hairColor: "Blue",
      facialHairType: "BeardMedium",
      clotheType: "Overall",
      eyeType: "Surprised",
      eyebrowType: "DefaultNatural",
      mouthType: "Smile",
      skinColor: "Light",
    },
    unlocked: false,
    price: 400,
    backgroundColor: "bg-whiteSnow",
  },
];

export default function StoreItems({ title, items }: StoreItemsProps) {
  const [openAchievementInfo, setOpenAchievementInfo] = React.useState(false);

  const handleClickOpen = () => {
    setOpenAchievementInfo(true);
  };

  const handleClose = () => {
    setOpenAchievementInfo(false);
  };

  return (
    <Container className="flex h-full w-fit" maxWidth={false}>
      <Grid
        container
        spacing={{ xs: 2, sm: 3, md: 3, xl: 3 }}
        columns={{ xs: 4, sm: 6, md: 12 }}
        className="bg-lightOrange pb-5 px-5 h-fit rounded-lg"
      >
        <Grid item xs={12}>
          <Typography className="text-left" variant="h5">
            {title}
          </Typography>
        </Grid>
        {itemList.map(({ avatar, unlocked, price, backgroundColor }, index) => {
          return (
            <Grid item xs={3}>
              <Box
                onClick={handleClickOpen}
                className={`flex flex-col cursor-pointer ${
                  unlocked ? "bg-success" : "bg-accentBlue"
                } pt-2 rounded-lg drop-shadow-xl`}
              >
                <Avatar
                  avatarStyle="Transparent"
                  topType={avatar.topType}
                  accessoriesType={avatar.accessoriesType}
                  hairColor={avatar.hairColor}
                  facialHairType={avatar.facialHairType}
                  clotheType={avatar.clotheType}
                  eyeType={avatar.eyeType}
                  eyebrowType={avatar.eyebrowType}
                  mouthType={avatar.mouthType}
                  skinColor={avatar.skinColor}
                  className={`h-24 w-5/6 rounded-t-lg ${backgroundColor} self-center justify-center`}
                />
                <Box
                  className={`bg-blue rounded-b-lg mt-2 self-center w-full ${
                    unlocked ? "bg-accentDarkGreen" : "bg-darkAccentBlue"
                  }`}
                >
                  {unlocked ? (
                    <Typography
                      align="center"
                      className="text-whiteSnow"
                      variant="h6"
                    >
                      Unlocked
                    </Typography>
                  ) : (
                    <Typography
                      align="center"
                      className="text-whiteSnow"
                      variant="h6"
                    >
                      <MonetizationOnIcon
                        className="text-whiteSnow"
                        fontSize="medium"
                        sx={{ mb: 0.5 }}
                      />
                      {price}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Dialog open={openAchievementInfo}>
        <DialogTitle>Unlock item</DialogTitle>
        <DialogContent>Do you want to buy this item?</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Back</Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            form="difficultyForm"
            id="difficultySubmit"
            disableElevation
            onClick={handleClose}
            style={{ gridArea: "confirm", width: "30%" }}
          >
            Buy
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
