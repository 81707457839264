import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: boolean = false

const completedSlice = createSlice({
  name: 'completed',
  initialState,
  reducers: {
    setCompleted: (state, action: PayloadAction<boolean>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCompleted } = completedSlice.actions

export default completedSlice.reducer