import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { SERVER_URL } from '../constants/AppConstants';
import { AuthenticationPayload, clearTokens, getRefreshToken, setAccessToken, setRefreshToken } from './auth';

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const api = axios.create({
  baseURL: SERVER_URL,
  responseType: 'json',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any) =>
  api
    .post<AuthenticationPayload>('/api/v1/auth/refresh', {
      method: 'POST',
      refreshToken: getRefreshToken(),
    })
    .then((response) => {
      console.log(response.data);
      setAccessToken(response.data.data.accessToken);
      setRefreshToken(response.data.data.refreshToken);
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + response.data.data.accessToken;
      return Promise.resolve();
    })
    .catch((error) => {
      clearTokens();
      return Promise.reject();
    });
// const refreshAuthLogic = (failedRequest:any) => {
//   api.post<AuthenticationPayload>('/api/refresh', { headers: { 'Authorization': `Bearer ${getRefreshToken()}` } })
//     .then(tokenRefreshResponse => {
//       localStorage.setItem(ACCESS_TOKEN_NAME, tokenRefreshResponse.data.data.payload.token)
//       failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.data.payload.token
//       return Promise.resolve()
//     })
//     // .catch(error => {
//     //   if (error.response) {
//     //     dispatch(setErrorMessage(error.response.statusText))
//     //   }
//     // })
// }

createAuthRefreshInterceptor(api, refreshAuthLogic);

export default api;
