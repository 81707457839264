import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type HintType = "freeTextHint" | "flipCardHint";

export interface Hint {
  type: HintType;
  cost: number;
}

export interface ShopInfo {
  [key: string]: number;
}

const initialState: ShopInfo = {};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setHintPrices: (state, action: PayloadAction<ShopInfo>) => {
      return (state = action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHintPrices } = shopSlice.actions;

export default shopSlice.reducer;
