import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface Question {
  'question': string,
  'reply': string
}

const initialState: Question[] = []

const questionHistorySlice = createSlice({
  name: 'questionHistory',
  initialState,
  reducers: {
    setQuestionHistory: (state, action: PayloadAction<Question[]>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setQuestionHistory } = questionHistorySlice.actions

export default questionHistorySlice.reducer