import { EmojiObjectsOutlined } from "@mui/icons-material"
import { Button } from "@mui/material"
import { Fragment, useRef } from "react"
import toast from "react-hot-toast"
import { Confirm } from "react-st-modal"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setAnswer } from "store/slices/answerSlice"
import { Action, setAskerAction } from "store/slices/askerActionSlice"
import { reduceCards, simplifyBoard } from "utils/utils"
import { useWebSocket } from "utils/WebSocketProvider"

export default function FlipBar({
  isGuessDisabled,
}: {
  isGuessDisabled: boolean;
}) {
  const cards = useAppSelector((state) => state.game.cards);
  const answer = useAppSelector((state) => state.game.answer);
  const question = useAppSelector((state) => state.game.question);
  const socket = useWebSocket();
  const dispatch = useAppDispatch();

  const originalCardState = useRef(reduceCards(cards));

  const handleGuess = () => {
    dispatch(setAskerAction(Action.GUESS));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (
      reduceCards(cards) === originalCardState.current &&
      !(await Confirm(
        "Don't forget to flip cards every round where applicable.",
        "No cards were flipped, are you sure?",
        "Proceed anyway",
        "Go back"
      ))
    ) {
      return false;
    }

    // Setting answer here because it wouldn't reset before
    dispatch(setAnswer(""));
    socket?.emit(
      "endTurn",
      simplifyBoard(cards),
      ({ success, message }) => {
        if (success) {
          toast.success(message);
        } else {
          toast.error(message);
        }
      }
    );
  };

  return (
    <Fragment>
      <form noValidate autoComplete="off" id="flipForm" onSubmit={handleSubmit}>
        <div>
          <span className="text-larger">{question}</span>
          <span className="answerText text-xlarge">{answer}</span>
        </div>
        <div>
          <span
            className="text-italic text-disabled text-smaller"
            style={{ lineHeight: 1.4 }}
          >
            Cover the cards that don't match the reply by pressing them on the
            board, then end the turn or make a guess. <br />
            Don't forget to finish flipping the cards before guessing.
          </span>
        </div>
        <Button
          color="secondary"
          variant="contained"
          type="submit"
          form="flipForm"
          id="flipSubmit"
          disableElevation
        >
          END TURN
        </Button>
      </form>
      {!isGuessDisabled && (
        <Button
          id="guess"
          variant="outlined"
          color="inherit"
          className="BigButton"
          size="large"
          name="guess"
          onClick={handleGuess}
          startIcon={<EmojiObjectsOutlined />}
          disableElevation
        >
          GUESS
        </Button>
      )}
    </Fragment>
  );
}
