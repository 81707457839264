import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: number = -1

const targetRelationSlice = createSlice({
  name: 'targetRelation',
  initialState,
  reducers: {
    setTargetRelation: (state, action: PayloadAction<number>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTargetRelation } = targetRelationSlice.actions

export default targetRelationSlice.reducer