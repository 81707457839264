import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import Card from 'components/Game/Card/Card';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAnswer } from 'store/slices/answerSlice';
import { Action, setAskerAction } from 'store/slices/askerActionSlice';
import { setCards } from 'store/slices/cardsSlice';
import { setDifficulty } from 'store/slices/difficultySlice';
import { setForfeited } from 'store/slices/forfeitedSlice';
import { setGainedExp } from 'store/slices/gainedExpSlice';
import { setGameover } from 'store/slices/gameoverSlice';
import { setGuessCard } from 'store/slices/guessCardSlice';
import { setItCard } from 'store/slices/itCardSlice';
import { setMyTurn } from 'store/slices/myTurnSlice';
import { setOpponentItCard } from 'store/slices/opponentItCardSlice';
import { setOpponent } from 'store/slices/opponentSlice';
import { setQuestion } from 'store/slices/questionSlice';
import { setReportChecked } from 'store/slices/reportCheckedSlice';
import { setRole } from 'store/slices/roleSlice';
import { setSession } from 'store/slices/sessionSlice';
import { setStage } from 'store/slices/stageSlice';
import { setTargetInput } from 'store/slices/targetInputSlice';
import { setTargetRelation } from 'store/slices/targetRelationSlice';
import { useWebSocket } from 'utils/WebSocketProvider';
import { getRemainingTasks, removeUnderscore } from 'utils/utils';

export default function GameoverView() {
  const dispatch = useAppDispatch();
  const socket = useWebSocket();
  const gainedExp = useAppSelector((state) => state.game.gainedExp);
  const opponentItCard = useAppSelector((state) => state.game.opponentItCard);
  const questionHistory = useAppSelector((state) => state.game.questionHistory);
  const reportChecked = useAppSelector((state) => state.game.reportChecked);
  const forfeited = useAppSelector((state) => state.game.forfeited);
  const progress = useAppSelector((state) => state.progress);

  const [level, setLevel] = useState(useAppSelector((state) => state.game.gainedExp.level.current));

  var [remainingTasks, taskCompletion] = getRemainingTasks(progress);

  const [reportOpen, setReportOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [experience, setExperience] = useState(
    ((gainedExp.exp.current + gainedExp.exp.increase) / gainedExp.level.levels[0].topExp) * 100,
  );

  const [count, setCount] = useState(0);

  // useEffect(() => {
  //   dispatch(refreshProgress());
  // }, []);

  useEffect(() => {
    const updateExp = () => {
      if (experience >= 100) {
        setExperience(0);
        setLevel((prevLevel) => prevLevel + 1);
        setCount((prevCount) => prevCount + 1);
      } else {
        const nextLevelExp = gainedExp.level.levels.at(count)?.topExp ?? 1; // avoiding division by 0
        const newExp = ((gainedExp.exp.current + gainedExp.exp.increase) / nextLevelExp) * 100;

        if (newExp >= 100) {
          // increase count
          // gainedExp.expIncrease =
          //   gainedExp.expIncrease -
          //   (gainedExp.currentExp + gainedExp.currentLevel.toNext);
          // // gainedExp.currentExp = 0;
          setExperience(100);
          setCount((prevCount) => prevCount + 1);
        } else {
          setExperience(newExp);
        }
      }
    };

    const timer = setTimeout(updateExp, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [count]);

  const handleToggle = (value: number) => () => {
    dispatch(setReportChecked(value));
  };

  const sendReport = () => {
    if (reportChecked.length !== 0) {
      socket?.emit('report', reportChecked, (success: boolean) => {
        if (success) {
          toast.success('Report sent! Thank you for your feedback.', {
            id: 'success',
            duration: 5000,
          });
        }
      });
      setSubmitted(true);
    }
    handleClose();
  };

  const handleClose = () => {
    setReportOpen(false);
  };

  const resetGame = () => {
    dispatch(setAskerAction(Action.NONE));
    dispatch(setTargetInput(''));
    dispatch(setTargetRelation(-1));
    dispatch(setGuessCard(-1));
    dispatch(setOpponent(''));
    dispatch(setSession(''));
    dispatch(setStage(''));
    dispatch(setMyTurn(false));
    dispatch(setRole(''));
    dispatch(setCards([]));
    dispatch(setItCard({ key: '', imageUrl: '' }));
    dispatch(setQuestion(''));
    dispatch(setAnswer(''));
    dispatch(setReportChecked(null));
    dispatch(setGameover(false));
    dispatch(setForfeited(false));
    dispatch(
      setGainedExp({
        won: false,
        bonus: 0,
        level: { current: 0, increase: 0, levels: [] },
        exp: { current: 0, increase: 0 },
        currency: { current: 0, increase: 0 },
      }),
    ); // TODO: fix this
    dispatch(setOpponentItCard({ key: '', imageUrl: '' }));
  };

  const startGame = () => {
    // if all easy tasks are done
    if (taskCompletion['EASY'] + 1 < 3) {
      // +1 because the game count is not yet updated at the end of the game, so we add it manually.
      dispatch(setDifficulty(0)); // 0 is EASY
    } else {
      dispatch(setDifficulty(1)); // 1 is MEDIUM
    }
    handleReset();
  };

  const handleReset = () => {
    socket?.emit('leaveGame');
    resetGame();
  };

  const report = () => {
    setReportOpen(true);
  };

  return (
    <div className="MainContainer">
      <Dialog
        open={reportOpen}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle>{'Select the questions with wrong answer'}</DialogTitle>
        <DialogContent>
          {questionHistory.length === 0 ? (
            <DialogContentText>No questions were asked</DialogContentText>
          ) : (
            <List>
              {questionHistory.map((value, index) => {
                const labelId = `checkbox-list-label-${index}`;

                return (
                  <ListItem
                    key={index}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(index)}
                    className="QuestionHistory-Items"
                    style={{
                      gridTemplateColumns: '2ch 1fr 5ch',
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={reportChecked.indexOf(index) !== -1}
                        tabIndex={-1}
                        color="secondary"
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value['question']} className="QuestionHistory-Items-Question" />
                    <ListItemText
                      primary={value['reply']}
                      title={value['reply']}
                      className="QuestionHistory-Items-Reply"
                    />
                  </ListItem>
                );
              })}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={sendReport} color="inherit" disabled={questionHistory.length === 0 ? true : false}>
            Report mistakes
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="MainContainer_content MainContainer_content-column"
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <span className={`text-xxlarge mb-16 text-bold ${gainedExp.won ? 'color-accent' : 'color-black'}`}>
          {gainedExp.won ? '🏆✨ You won!!!' : '🥈🥺 You lost...'}
        </span>
        {/* <Box sx={{ width: "50%", paddingBottom: "16px" }}>
          <Typography variant="h6">Lvl. {level}</Typography>
          <LinearProgressWithLabel value={experience} />
        </Box> */}
        <Grid
          container
          spacing={2}
          columns={{ xs: 3, sm: 5, md: 12 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="min-w-full pt-20 pb-2 pl-4 pr-4"
        >
          <Grid item xs={6}>
            <Box className="flex flex-col justify-center text-center">
              <span className="mb-8 text-large">
                The opponent's card was
                <b className="color-accent"> {removeUnderscore(opponentItCard.key)}</b>
              </span>
              <Card
                imageLink={opponentItCard.imageUrl}
                name={opponentItCard.key}
                definitions={opponentItCard.definitions}
                unflippable
                gameover
                className="mb-4 place-self-center"
              />
            </Box>
          </Grid>
          <Grid item xs={6} className="p-4 rounded-lg bg-lightOrange">
            <Grid container spacing={2} columns={{ xs: 8 }} direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h5" className="SummaryHeader">
                  Points received!
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Divider className="bg-orangePastel" orientation="horizontal" />
              </Grid>
              <Grid item xs={3}>
                <Typography align="left">
                  <b>Level:</b> {gainedExp.level.current}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="text-accentBlue">
                  <b>+{gainedExp.level.increase}</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="right">{gainedExp.level.current + gainedExp.level.increase}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Divider className="bg-orangePastel" orientation="horizontal" />
              </Grid>
              <Grid item xs={3}>
                <Typography align="left">
                  <b>Currency:</b> {gainedExp.currency.current}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography className="text-accentBlue">
                  <b>+{gainedExp.currency.increase}</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="right">{gainedExp.currency.current + gainedExp.currency.increase}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Divider className="bg-orangePastel" orientation="horizontal" />
              </Grid>
              {/* <Grid item xs={6}>
                <Typography>Lost: {gainedExp.endingLevel.level}</Typography>
              </Grid> */}
              <Grid item xs={4}>
                <b className="float-left">Current experience:</b>
              </Grid>
              <Grid item xs={4}>
                <span className="float-right">{gainedExp.exp.current}</span>
              </Grid>
              <Grid item xs={4}>
                <b className="float-left">Gained experience:</b>
              </Grid>
              <Grid item xs={4}>
                <span className="float-right">{gainedExp.exp.increase}</span>
              </Grid>
              <Grid item xs={4}>
                <b className="float-left">Bonus:</b>
              </Grid>
              <Grid item xs={4}>
                <span className="float-right">{gainedExp.bonus}</span>
              </Grid>
              <Grid item xs={8}>
                <Divider className="bg-orangePastel" orientation="horizontal" />
              </Grid>
              <Grid item xs={4}>
                <b className="float-left">Total:</b>
              </Grid>
              <Grid item xs={4}>
                <span className="float-right">{gainedExp.exp.current + gainedExp.exp.increase + gainedExp.bonus}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="mb-8 QuestionHistory" style={{ width: '60%', minWidth: '18em' }}>
          {questionHistory.length === 0 ? (
            'No questions were asked'
          ) : (
            <div className="QuestionHistory-Items">
              {questionHistory.map((turn, index) => {
                return (
                  <Fragment key={index}>
                    <span key={`question-${index}`} className="QuestionHistory-Items-Question">
                      {turn['question']}
                    </span>
                    <span key={`reply-${index}`} className="QuestionHistory-Items-Reply" title={turn['reply']}>
                      {turn['reply']}
                    </span>
                  </Fragment>
                );
              })}
            </div>
          )}
        </div>

        {questionHistory.length === 0 ? null : (
          <Button
            // label="Report"
            variant="text"
            color="inherit"
            className="mb-8 text-large"
            size="large"
            disableElevation
            disabled={submitted ? true : false}
            onClick={report}
          >
            Report mistakes
          </Button>
        )}
        <br />
        {/* {remainingTasks - (forfeited ? 0 : 1) < 0 ?
          <Link onClick={handleReset} to="/lobby" className="mb-8 MenuButton text-large">{won ? "Play again" : "Try again"}</Link> :
          remainingTasks - (forfeited ? 0 : 1) === 0 ?
            <Link onClick={startGame} to="/exit_questionnaire" className="mb-8 MenuButton text-large">Proceed to ending questionnaire</Link> :
            <Link onClick={startGame} to="/lobby" className="mb-8 MenuButton text-large">Proceed to next task</Link>} */}
        <Link onClick={handleReset} to="/" className="MenuButton MenuButton--auth text-large">
          Back to main menu
        </Link>
      </div>
    </div>
  );
}
