import * as React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CropDinIcon from "@mui/icons-material/CropDin";
import PersonIcon from "@mui/icons-material/Person";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";

export default function StoreMenu() {
  return (
    <Container className="flex h-full w-fit" maxWidth={false}>
      <MenuList className="bg-orangePastel h-full text-whiteSnow rounded-lg">
        <MenuItem component={Link} to="avatars">
          <ListItemIcon>
            <PersonIcon className="text-whiteSnow" fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Avatars</ListItemText>
        </MenuItem>
        <Divider className="bg-whiteSnow" />
        <MenuItem component={Link} to="frames">
          <ListItemIcon>
            <CropDinIcon className="text-whiteSnow" fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Frames</ListItemText>
        </MenuItem>
        <Divider className="bg-whiteSnow" />
        <MenuItem component={Link} to="backgrounds">
          <ListItemIcon>
            <CropOriginalIcon className="text-whiteSnow" fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Backgrounds</ListItemText>
        </MenuItem>
        <Divider className="bg-whiteSnow" />
        <MenuItem component={Link} to="coins">
          <ListItemIcon>
            <MonetizationOnIcon className="text-whiteSnow" fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Coins</ListItemText>
        </MenuItem>
      </MenuList>
    </Container>
  );
}
