import Card from "components/Game/Card/Card";
import Player from "components/Game/Player/Player";
import React from "react";
import { useAppSelector } from "store/hooks";

const TopBar = () => {
  const me = useAppSelector((state) => state.game.me);
  const itCard = useAppSelector((state) => state.game.itCard);
  const opponent = useAppSelector((state) => state.game.opponent);

  return (
    <div className="mb-4 TopBar text-large">
      <Player username={me} id="Player1" />
      <Card
        imageLink={itCard.imageUrl}
        name={itCard.key}
        definitions={itCard.definitions}
        unflippable
        showName
      />
      <Player username={opponent} id="Player2" />
    </div>
  );
};

export default React.memo(TopBar);
