import { useAppSelector } from "store/hooks"

const StatusBar = () => {
  const role = useAppSelector(state => state.game.role)
  const myTurn = useAppSelector(state => state.game.myTurn)

  return (
    <div className="StatusBar">
      <div className="PlayerRole text-xlarge">You are the <b className="color-accent">{role}</b></div>
    </div>
  )

}

export default StatusBar