import { EmojiObjectsOutlined, QuestionAnswerOutlined } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { getInventory } from 'components/Lobby/PlayerData';
import { ACTION_ASK, ACTION_GUESS, difficulties } from 'constants/AppConstants';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Action, setAskerAction } from 'store/slices/askerActionSlice';
import { flipCard } from 'store/slices/cardsSlice';
import { useWebSocket } from 'utils/WebSocketProvider';

export default function ChoiceBar({ isGuessDisabled }: { isGuessDisabled: boolean }) {
  const action = useAppSelector((state) => state.game.action);
  const dispatch = useAppDispatch();
  const [openHintDialog, setOpenHintDialog] = useState(false);
  const cards = useAppSelector((state) => state.game.cards);
  const unflippedCards = cards.filter((card) => card.isFlipped === false);
  const socket = useWebSocket();

  const inventory = useAppSelector((state) => state.inventory);
  const [cardFlipQuantity, setCardFlipQuantity] = useState(0);

  const questionHistory = useAppSelector((state) => state.game.questionHistory);
  const difficulty = useAppSelector((state) => state.gameDifficulty);

  const [usedCardFlip, setUsedCardFlip] = useState(false);

  useEffect(() => {
    socket && dispatch(getInventory(socket));
    checkCanFlipCard();
  }, []);

  useEffect(() => {
    setCardFlipQuantity(inventory['flipCardHint'] ?? 0);
  }, [inventory]);

  const handleAsk = () => {
    dispatch(setAskerAction(Action.ASK));
  };
  const handleGuess = () => {
    dispatch(setAskerAction(Action.GUESS));
  };

  const checkCanFlipCard = () => {
    socket?.emit('usedCardFlip', (usedFlip: boolean) => {
      setUsedCardFlip(usedFlip);
    });
  };

  const handleUseHint = () => {
    socket?.emit('flipCardHint', ({ success, hintCard, message }) => {
      if (success) {
        dispatch(flipCard(hintCard));
        toast.success(message);
      } else {
        toast.error(message, { id: 'error' });
      }
      socket && dispatch(getInventory(socket));
      checkCanFlipCard();
      setOpenHintDialog(false);
    });
  };

  // const handleCloseHintDialog = () => {
  //   if (coins > 50) {
  //     //TODO: swap this
  //     toast.error(`You don't have enough coins to buy a hint`, {
  //       id: "error",
  //     });
  //   } else {
  //     const cardsWithIndex = cards.map((card, i) => {
  //       return { card, i };
  //     });

  //     const options = cardsWithIndex.filter(
  //       ({ card }) => card.isFlipped === false && card.id !== opponentItCard.id
  //     );

  //     if (options.length < 3) {
  //       toast.error(`You need more than 3 unflipped cards to use the hint.`, {
  //         id: "error",
  //       });
  //     } else {
  //       setOpenHintDialog(false);
  //       const hintIndex = Math.floor(Math.random() * options.length);
  //       dispatch(flipCard(options[hintIndex].i));
  //     }
  //   }
  // };

  return (
    <div id="ChoiceBar">
      <Button
        id="ask"
        variant={`${action === ACTION_ASK ? 'contained' : 'outlined'}`}
        color={`${action === ACTION_ASK ? 'secondary' : 'inherit'}`}
        className="btn"
        size="large"
        style={{ borderRadius: '2em' }}
        onClick={handleAsk}
        startIcon={<QuestionAnswerOutlined />}
        disableElevation
      >
        ASK
      </Button>
      {cardFlipQuantity === 0 || unflippedCards.length < 3 || usedCardFlip ? (
        <Tooltip
          title={
            usedCardFlip
              ? 'You already used a hint during this game.'
              : "You don't have enough hints or you have less than 3 unflipped cards on your board."
          }
          placement="bottom"
          arrow
        >
          <div>
            <Button
              id="hint"
              variant="outlined"
              color="inherit"
              className="btn"
              size="large"
              style={{ borderRadius: '2em' }}
              onClick={() => setOpenHintDialog(true)}
              startIcon={<EmojiObjectsOutlined />}
              disableElevation
              disabled
            >
              HINT
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          id="hint"
          variant="outlined"
          color="inherit"
          className="btn"
          size="large"
          style={{ borderRadius: '2em' }}
          onClick={() => setOpenHintDialog(true)}
          startIcon={<EmojiObjectsOutlined />}
          disableElevation
        >
          HINT
        </Button>
      )}
      {isGuessDisabled ? (
        <Tooltip
          title={`You need to ask at least ${
            difficulties[difficulty].minQuestions
          } questions before guessing. Questions asked: ${questionHistory.length ?? 0}.`}
          placement="bottom"
          arrow
        >
          <div>
            <Button
              id="guess"
              variant={`${action === ACTION_GUESS ? 'contained' : 'outlined'}`}
              color={`${action === ACTION_GUESS ? 'secondary' : 'inherit'}`}
              className="btn"
              size="large"
              name="guess"
              style={{ borderRadius: '2em' }}
              onClick={handleGuess}
              startIcon={<HelpOutlineIcon />}
              disableElevation
              disabled
            >
              GUESS
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          id="guess"
          variant={`${action === ACTION_GUESS ? 'contained' : 'outlined'}`}
          color={`${action === ACTION_GUESS ? 'secondary' : 'inherit'}`}
          className="btn"
          size="large"
          name="guess"
          style={{ borderRadius: '2em' }}
          onClick={handleGuess}
          startIcon={<HelpOutlineIcon />}
          disableElevation
        >
          GUESS
        </Button>
      )}

      <Dialog open={openHintDialog}>
        <DialogTitle>{`Are you sure you want to use a card flip hint?`}</DialogTitle>
        <DialogContent>
          This hint will flip one card that is not your opponent's IT card for you. You need at least 3 open cards to
          use this hint. You can use this hint only <b>once</b> per game. <br />
          <b>Available hints: </b>x{cardFlipQuantity}.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenHintDialog(false)}>Back</Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disableElevation
            disabled={cardFlipQuantity === 0}
            onClick={handleUseHint}
            style={{ gridArea: 'confirm', width: '30%' }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
